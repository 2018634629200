import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import Modal from '@mui/material/Modal';
import SubscribeFlow from './subscribeFlow';
import axios from 'axios';
import { toast } from "react-toastify";

const steps = [
    'Email Verification',
    'Terms and Conditions',
    'User Verification',
    'Risk Profiling',
    'Curated Portfolios'
  ];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

function CuratedPortfolios(props){

    const [portfolioList, setPortfolioList] = useState([]);
    const [portfolioId, setPortfolioId] = useState();
    const rp = {
        'Very Conservative' : 'very_conservative',
        'Conservative' : 'conservative',
        'Moderate' : 'moderate',
        'Agressive' : 'agressive',
        'Very Agresive' : 'very_agressive'
    }
    const handleSubscribe = async () => {
        console.log('test');
        toast.success('Portfolio Subscribed Successfully')
    }
    const [open, setOpen] = React.useState(false);
    const [portfolioName, setPortfolioName] = useState('');
    const handleOpen = (event) => {
        console.log(event.target.value, 'vale')
        setOpen(true);
        console.log(event.target.getAttribute('data-id'), 'data id')
        setPortfolioId(event.target.getAttribute('data-id'))
        // setPortfolioId(event.target.value);
        setPortfolioName(event.target.value)
    }
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getPortfoliosList();
    },[])

    const getPortfoliosList = async() => {
        const formData = {
            risk_profile: rp[props.riskProfile]
        }
        console.log(formData, 'form data')
        const res = await axios.get('http://localhost:5000/portfolio/get_curated_portfolios_list', {params: formData})
        const resp_data = res.data.portfolios
        setPortfolioList(resp_data)
        
    }
    return (
        <>
            <Box sx={{ width: '80%'}} mt={10} ml={15}>
                <Stepper activeStep={5} alternativeLabel>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </Box>
            <div className='ports-data-box'>
                <Box width={'70%'} mt={10} className='port-box'> 
                    {portfolioList.map((item, index) => (
                    <Card sx={{ width: 300, height:300, display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
                        <CardHeader height={30} avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">{item.portfolio_name.split(" ")[0][0]}</Avatar>} title={item.portfolio_name} subheader={"Equity . "+item.risk_profile}/>
                        <CardContent>
                            <Box width={'100%'} className='port-details'>
                                <div>
                                    <Typography variant="caption">Live Return</Typography>
                                    <Typography>{item.live_return+"%"}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption">Rebalance</Typography>
                                    <Typography>{item.rebalance.charAt(0).toUpperCase()+item.rebalance.slice(1)}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption">Min Investment</Typography>
                                    <Typography>{item.min_investment}</Typography>
                                </div>
                            </Box>
                            <Box width={'100%'}  className='port-details'>
                                <div>
                                    <Typography variant="caption">No.of Subscribers</Typography>
                                    <Typography>{item.subscribed}</Typography>
                                </div>
                                {/* <div>
                                    <Typography variant="caption">Rebalance</Typography>
                                    <Typography>{item.rebalance.charAt(0).toUpperCase()+item.rebalance.slice(1)}</Typography>
                                </div>
                                <div>
                                    <Typography variant="caption">Min Investment</Typography>
                                    <Typography>{item.min_investment}</Typography>
                                </div> */}
                            </Box>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {item.description}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{margin:'0 auto', justifyContent:'center'}} height={40}>
                            <Button size="small" onClick={handleOpen} data-id={item.portfolio_id} value={item.portfolio_name}>Subscribe</Button>
                        </CardActions>
                    </Card>    
                    ))}
                    <Modal open={open} onClose={handleClose} aria-labelledby="subscribe-modal-title" aria-describedby="subscribe-modal-description">
                        <Box sx={style}>
                            <SubscribeFlow portfolio_id={portfolioId} portfolio_name={portfolioName} nextStep={props.nextStep}></SubscribeFlow>
                        </Box>
                    </Modal>
                    
                </Box>
            </div>
        </>
    )
}

export default CuratedPortfolios