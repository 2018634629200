import React, { useEffect, useState } from "react";
import { Grid2 } from "@mui/material";
import Header from "../../components/common/header";
import PortfolioMetaInputs from "../../components/portfolio/portfolioMetaInputs";
import SymbolEvalData from "../../components/portfolio/symbolEvalData";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";


const NewPortfolio = () => {
    const {type, userId} = useParams();

    const navigate = useNavigate();
    const [showGraph, setShowGraph] = useState(false);
    const [symbol, setSymbol] = useState('');
    const [formData, setFormData] = useState({
        portfolio_name : '',
        risk_profile : 'very_conservative',
        min_investment : 0,
        rebalance : 'weekly',
        portfolio_coverage : '',
        weighted_beta : 0,
        selected_stocks : [],
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
        // console.log(formData, 'form data bro')
      };

    const updateFormData = (newData) => {
        // console.log(newData, 'new data')
        setFormData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };
    useEffect(()=>{
        calMinInvestment();
    },[formData])
    const calMinInvestment = async() => {
        // console.log('Caluclating Minimum Investment dude');
        const symbolElementsList = document.getElementsByName('symbol_name')
        const qtyElementsList = document.getElementsByName('symbol_quantity')
        const priceElementsList = document.getElementsByName('symbol_price')
        var minv = 0
        for (var i=0; i<symbolElementsList.length; i++){
            // console.log(priceElementsList[i].value, 'price')
            // console.log(qtyElementsList[i].value, 'qty')
            const val = parseFloat(priceElementsList[i].value) * parseInt(qtyElementsList[i].value)
            console.log(minv, 'min v')
            console.log(val, 'value bto')
            minv = (parseFloat(minv) + parseFloat(val)).toFixed()
        }
        // setMinInvestment(minv)
    
        updateFormData({['min_investment']: minv})
    }
    const handleSubmit = async(event) => {
        // const formData = {
        //     'portfolio_name' : document.getElementById('portfolio_name').value,
        //     'risk_profile' : riskProfile,
        //     'min_investment' : minInvestment,
        //     'rebalance' : rebalance,
        //     'portfolio_coverage' : portfolioCoverageData,
        //     'type' : event.target.value,
        //     'weighted_beta' : weightedBeta
        // }
        const formObject = formData
        
        const symbolElementsList = document.getElementsByName('symbol_name')
        const qtyElementsList = document.getElementsByName('symbol_quantity')
        const priceElementsList = document.getElementsByName('symbol_price')
        const coverageElementsList = document.getElementsByName('symbol_coverage')

        const stockData = []
        for (var i=0; i<symbolElementsList.length; i++){
            const tempData = {
                symbol : symbolElementsList[i].value,
                quantity : qtyElementsList[i].value,
                price : priceElementsList[i].value,
                coverage : coverageElementsList[i].value,
                asset_type : 'equity'
            }
            stockData.push(tempData)
        }
        formObject['symbol_data'] = stockData
        formObject['type'] = event.target.value
        delete formObject['selected_stocks']
        console.log(formObject, 'form object dude')
        if (!formObject.portfolio_name){
            toast.error('Please Add Portfolio Name')
            return
        }
        try {
            const res = await axios.post('http://localhost:5000/portfolio/create_curated_portfolio', formObject)
            toast.success('Portfolio Created Successfully')
            navigate('/dashboard/'+type+'/'+userId)
        } catch(err){
            if (!err.response){
                console.log(err, 'error')
                toast.error(err.message, 'error message')
            } else {
                console.log(err, 'error bro')
                toast.error(err.response.data.message);
                console.log(err.response.data.message, 'message from api')
            }
        }
        // console.log(formObject);
        console.log('Submit Processed');
    }
    const handleShowStock = (e) => {
        setSymbol(e.value)
        setShowGraph(true);
    }
    return(
        <>
            <Header userType={type} userId={userId} stocksList={true} handleChange={handleShowStock}></Header>
            <Grid2 sx={{ flexGrow: 1 }} container spacing={1} mt={1} ml={1}>
                <Grid2 xs={12}>
                    <Grid2 container spacing={2} sx={{ justifyContent: 'left' }}>
                        <Grid2 key={0}>
                            <PortfolioMetaInputs formData={formData} onInputChange={handleInputChange} onSubmit={handleSubmit} />
                        </Grid2>
                        <Grid2 key={1}>
                            {showGraph ?
                                <SymbolEvalData symbol={symbol} formData={formData} handleInputChange={updateFormData}/>
                                : ''
                            }
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </>       
    )
}

export default NewPortfolio