import axios from "axios";
import React from "react";
import { Button, Typography } from "@mui/material";
import { json } from "react-router-dom";
import kiteLogo from './zerodha_logo.png'
// import Hid

const KiteOffsiteButton = (props) => {


    const handleSubmit = async() => {
        const formData = {
            portfolio_id : props.portfolio_id
        }
        const res = await axios.get('http://localhost:5000/broker/get_kite_params', {params:formData})
        console.log(res.data, 'res data')
        const basketItems = JSON.stringify(res.data)
        const publishData = {
            api_key : 'ipaqxfacwd3fxtlx',
            data : basketItems
        }
        console.log(publishData);
        // const d = JSON.stringify([
        //     {
        //       "variety": "regular",
        //       "tradingsymbol": "INFY",
        //       "exchange": "NSE",
        //       "transaction_type": "BUY",
        //       "order_type": "MARKET",
        //       "quantity": 10,
        //       "readonly": false
        //     },
        //     {
        //       "variety": "regular",
        //       "tradingsymbol": "NIFTY15DECFUT",
        //       "exchange": "NFO",
        //       "transaction_type": "SELL",
        //       "order_type": "LIMIT",
        //       "price": 7845,
        //       "quantity": 1,
        //       "readonly": false
        //     },
        //     {
        //       "variety": "co",
        //       "tradingsymbol": "RELIANCE",
        //       "exchange": "NSE",
        //       "transaction_type": "BUY",
        //       "order_type": "LIMIT",
        //       "product": "MIS",
        //       "price": 915.15,
        //       "quantity": 1,
        //       "trigger_price": 910,
        //       "readonly": true
        //     }
        //   ])
        // const response = await fetch('https://kite.zerodha.com/connect/basket', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(publishData),
        //   });
        // // const result = await axios.post('https://kite.zerodha.com/connect/basket', {json:publishData}, {headers: {'Content-Type':'application/json'}})
        // console.log(response);
        const basket = []
        res.data.forEach((item)=>{
            const d = {
                "variety": item.variety,
                "tradingsymbol": item.tradingsymbol,
                "exchange": item.exchange,
                "transaction_type": item.transaction_type,
                "order_type": item.order_type,
                "quantity": item.quantity,
                "readonly": true
            }
            basket.push(d)
        })
        document.getElementById('basket').value = JSON.stringify(basket)
        document.getElementById('redirect_params').value = 'portfolio_id='+props.portfolio_id
        document.getElementById('portfolio_id').value = 'portfolio_id='+props.portfolio_id
        document.getElementById('basket-form').submit();
    }
    return (
        <>
        <form method="post" id="basket-form" action="https://kite.zerodha.com/connect/basket">
            <input type="hidden" name="api_key" value="1kxbi1bmhbzfcuts" />
            <input type="hidden" id="basket" name="data" value="" />
            <input type="hidden" id="redirect_params" name="redirect_params" value="" />
            <input type="hidden" id="portfolio_id" name="portfolio_id" value="" />
        </form>
        {/* <Button id='execute-button' onClick={handleSubmit} >Execute</Button> */}
        <Button variant="contained" id='execute-button' onClick={handleSubmit} sx={{ display: 'flex', flexDirection:'column',alignItems: 'center', backgroundColor: 'white', color: 'black', padding: '10px 20px', '&:hover': { color: '#ff5722', borderColor:'#ff5722'},border: '1px solid black' }} >
            <img src={kiteLogo} alt="logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
            <Typography variant="button">Zerodha</Typography>
        </Button>
        </>
    )
}

export default KiteOffsiteButton