import React from "react";
import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <>
            {/* <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Box width={'60%'} border={'1px solid lightgrey'} mt={2} p={2}>  */}
                <p class='title1'>Hedgeloop - Privacy Policy</p>
                <div className='terms-box'>
                    <Typography variant="h4">Privacy Policy</Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        Tyck.in web and the mobile application ‘Tyck’ is a product owned and operated by Hedgeloop Technologies Private Limited (hereinafter referred to as “Hedgeloop Technologies”, “us”, “we”). Hedgelooop Technologies is committed to safeguarding any information submitted to us on our website (“Site”) and/or the services made available through the Site or mobile applications (collectively referred to as the “Platform”) of Hedgeloop Technologies (“Services”). The privacy notice provides you with a summary of the type of information collected by us, policy regarding use of that information and measures taken to safeguard that information.
                    </Typography>  
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        We may also allow our group companies, affiliates and subsidiary companies, and third parties to use our Platform to offer their products and services to you, under applicable terms and conditions specified on our Platform from time to time.
                    </Typography>
                    <Typography variant="h4">User Consent and Agreement</Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        By submitting Personal Information through our Platform or Services, you agree to the terms of this Privacy Policy and you expressly give your voluntary consent to the collection, use and disclosure of the Personal Information in accordance with this Privacy Policy.
                    </Typography>
                    <Typography variant="h6">
                        Information you provided to us
                    </Typography>
                    <Typography component={'ul'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            When you participate in one of our surveys, we may collect additional information.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            We may collect Personal and financial information provided by you, such as your first and last name, phone, e-mail address, age, investments, PAN etc. while you access services on the platform
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            We may also collect Personal Information on the Platform or through the Services when we make clear that we are collecting it and you voluntarily provide the same, for example when you submit an application for employment.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            We may collect necessary documents, to provide services including credit facilitation, when you voluntarily provide the same through one-time access to files or images on your device
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            You must ensure to recheck the information or data being provided to us. In case any of the data/information is found to be inaccurate or incorrect, the same shall be informed to us for immediate amendment. We shall not be responsible for the authenticity of any information including personal information submitted to us.
                        </Typography>
                    </Typography>
                    <Typography variant="h6">
                        Information collected via technology
                    </Typography>
                    <Typography component={'ul'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To make our Platform and Services more useful to you, our servers (which may be hosted by a third-party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), and domain name.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            We may use third party service providers to help us analyze certain online activities. For example, these service providers may help us analyze visitor activity on the Platform. We may permit these service providers to use cookies and other technologies to perform these services for us.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            We use authorised third-party service providers to power payment transactions on our platform. Further, as per the applicable regulations, we use tokenisation services from certified third-party service providers to facilitate card transactions on our Platform. The third-party service providers use/process such information and further transfer your information to the card networks (such as Visa, Mastercard). Such third-party service providers have the necessary rights to use/process/transfer such information to provide tokenisation services in accordance with the applicable regulations. Such third-party service providers are PCI-DSS compliant. We do not store any information that is provided by you to facilitate such card transactions on our Platform.
                        </Typography>
                    </Typography>
                    <Typography variant="h6">
                        Use of personal information
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        In general, personal Information you submit to us regarding you or your company is used either to respond to requests that you make, or to aid us in serving you better. We use such Personal Information in the following ways:
                    </Typography>
                    <Typography component={'ul'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To identify you as a user in our system;
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To provide improved administration of our Platform and Services;
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To provide the Services you request;
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To improve the quality of experience when you interact with our Platform and Services;
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To send you e-mail and other notifications;
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To send newsletters, surveys, offers, and other promotional materials related to our Services and for other marketing purposes.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Protect our services and our users.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Market and customer analysis, market research and statistics.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            To prevent and detect fraud or abuse of our services as well as to prevent, detect and investigate any potential unlawful or prohibited activities.
                        </Typography>
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        Information like your demographic information, financial information like bank account details etc, investment information like holdings etc, personal information like phone number, email etc, and other information like PAN/Aadhar will be utilised for and shared with regulated entities like Investment Advisors, Research Analysts, Brokers, NBFCs, Banks, Credit Bureaus etc for various regulatory purposes (KYC, AML etc) and for various business purposes (credit facilitation, tracking services etc ).
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        Additionally, your demographic information, financial information, investment information and other data might be shared with the group companies defined indisclosures and other third parties in accordance with the terms and conditions mentioned here
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Specifically, in the case of the credit facilitation services being provided to you, the following data is being shared with authorised third parties:
                    </Typography>
                    <Typography component={'ul'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            PAN, Mobile no. and Email ID for investment holdings verification are shared with SEBI-registered RTAs and Depositories
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            KYC documents for OCR and bank account for mandate setup with Digio (Digio is a RBI-regulated entity)
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Email ID for communication with Amazon Web Services
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Phone No. for SMS communication with SMS service provider Kaleyra
                        </Typography>
                    </Typography>
                    <Typography variant="h6">
                        Disclosure to third parties
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        We may be required from time to time to disclose any personal information collected by us to governmental or judicial bodies or agencies or our regulators based on their requirement to comply with the applicable laws including cyber laws. We may share your information including personal information to third parties including group companies to enable the provision of services to you and/ or to carry out technical, logistical and other functions on our behalf. (eg. Sending emails or newsletters to users). Specifically, for credit facilitation services, please refer to the above section.
                    </Typography>
                    <Typography variant="h6">
                        Creation of anonymous information
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        We may create Anonymous Information records from Personal Information by excluding information (such as the name and email). We use this Anonymous Information to analyze request and usage patterns so that we may enhance the content of our Services and improve Platform navigation. smallcase Technologies reserves the right to use Anonymous Information for any purpose and disclose Anonymous Information to third parties at its sole discretion.
                    </Typography>
                    <Typography variant="h6">
                        Third party websites
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        When you click on a link to any other website or location, you will leave our Platform and go to another site and another entity may collect Personal Information or Anonymous Information from you. We have no control over, do not review, and cannot be held responsible for, these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any collection of information after you click on links to such outside websites.
                    </Typography>
                    <Typography variant="h6">
                        Cookies
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        "Cookies" are pieces of information that a browser can record after visiting a website. We may use cookies for technical purposes such as to enable better navigation through our site, or to store user preferences for interacting with the site. If you turn off the option to have cookies stored on your browser, it will affect your experience on smallcase.com
                    </Typography>
                    <Typography variant="h6">
                        Security of your information
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        Hedgeloop Technologies is committed to protecting the security and privacy of your Personal Information. We endeavour to have suitable technical, operational and physical security controls and measures to protect information collected by us that are commensurate with the nature of our business at all times. We use a variety of industry-standard security technologies and procedures to help protect your Personal Information from unauthorized access, use, or disclosure, and periodically review the same.In the event of any breach of our security control measures resulting in unauthorised access to your information, we will endeavour to inform you of the extent of such breach, subject to applicable law and cooperation with the relevant authorities. Further, despite our efforts to protect your information, if unauthorized persons breach security control measures and illegally use such sensitive/personal data or information, Smallcase Technologies, its affiliates and directors shall not be held responsible/liable.
                    </Typography>
                    <Typography variant="h6">
                        Retention of information/data
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        It may be noted that we may retain the data as may be provided to us till such time as may be provided under the law and as required to efficiently provide service to our users.
                        For any data as well as consent that you may have provided and want to be deleted or revoked (fully or partially), please send an email stating the details of the data and/or consent provided and the scope/nature of the deletion/revocation desired.
                        However, in the event of you withdrawing such consent or making a request to delete the data, you would not be able to use the Services on the Platform.
                    </Typography>
                    <Typography variant="h6">
                        Amendments/Modifications
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        This Privacy Policy available on our website is current and valid. However, smallcase Technologies reserves the right to amend/modify any of the sections of this policy at any time and the general public are requested to keep themselves updated for changes by reading the same from time to time.
                    </Typography>
                    <Typography variant="h6">
                        Contact Information
                    </Typography>
                    <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        Hedgeloop welcomes your grievances, comments or questions regarding the term of this Privacy Policy, the Platform, or the Services. Please e-mail us info@hedgeloop.com 
                    </Typography>
                    <Typography variant="h6">
                        Definitions
                    </Typography>
                    <Typography component={'ul'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Anonymous Information - Anonymous information means information that does not directly or indirectly identify, and cannot reasonably be used to identify, an individual guest/user.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Internet Protocol (IP) - Internet Protocol (IP) is the principal set (or communications protocol) of digital message formats and rules for exchanging messages between computers across a single network or a series of interconnected networks, using the Internet Protocol Suite (often referred to as TCP/IP). Messages are exchanged as datagrams, also known as data packets or just packets.
                        </Typography>
                        <Typography component={'li'} sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                            Personal Information - Personal Information means any information that relates to a natural person, which, either directly or indirectly, in combination with any other information available, is capable of identifying such person.
                        </Typography>
                    </Typography>
                    {/* </Box>
            </Box> */}
            </div>
        </>
    )
}

export default PrivacyPolicy