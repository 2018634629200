import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import { Box, Button, Card, CardHeader, CardContent, CardActions, Menu, MenuItem, Typography, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {red} from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';


const PortfolioCard = (props) => {
 
    const navigate = useNavigate();
    const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
    const optionsOpen = Boolean(optionsAnchorEl);

    const handleOptionsMenuClick = (event) => {
            setOptionsAnchorEl(event.currentTarget);
    };
    
    const handleOptionsMenuClose = (option) => {
        setOptionsAnchorEl(null);
    };

    function getOptions(props){
        const fundOptions1 = ['un-publish', 'edit', 'delete']
        const fundOptions2 = ['publish', 'edit', 'delete']
        const userOptions1 = ['subscribe', 'remove']
        const userOptions2 = ['un-subscribe', 'remove']

        var menuItems = []
        if ( props.userType === 'fund'){
            console.log('in fund')
            if (props.portfolioData.status === true) {
                console.log('in fund true')
                 return (
                    [
                        <MenuItem key='un-publish' onClick={() => handleActions('un-publish')}>
                            <Typography textAlign="center">{'un-publish'}</Typography>
                        </MenuItem>,
                        <MenuItem key='edit' onClick={() => handleActions('edit')}>
                            <Typography textAlign="center">{'edit'}</Typography>
                        </MenuItem>,
                        <MenuItem key='delete' onClick={() => handleActions('delete')}>
                            <Typography textAlign="center">{'delete'}</Typography>
                        </MenuItem>
                    ]
                 )
            } else {
                console.log('in fund false')
                return (
                    [
                        <MenuItem key='publish' onClick={() => handleActions('publish')}>
                            <Typography textAlign="center">{'publish'}</Typography>
                        </MenuItem>,
                        <MenuItem key='edit' onClick={() => handleActions('edit')}>
                            <Typography textAlign="center">{'edit'}</Typography>
                        </MenuItem>,
                        <MenuItem key='delete' onClick={() => handleActions('delete')}>
                            <Typography textAlign="center">{'delete'}</Typography>
                        </MenuItem>
                    ]
                 )
            }
        } else  if ( props.userType === 'user'){
            console.log('in user')
            if (props.portfolioData.status === true) {
                console.log('in user true')
                return (
                    [
                        <MenuItem key='un-subscribe' onClick={() => handleActions('un-subscribe')}>
                            <Typography textAlign="center">{'un-subscribe'}</Typography>
                        </MenuItem>,
                        <MenuItem key='remove' onClick={() => handleActions('remove')}>
                            <Typography textAlign="center">{'remove'}</Typography>
                        </MenuItem>
                    ]
                 )
            } else {
                console.log('in fund false')
                return ([
                        <MenuItem key='subscribe' onClick={() => handleActions('subscribe')}>
                            <Typography textAlign="center">{'subscribe'}</Typography>
                        </MenuItem>,
                        <MenuItem key='remove' onClick={() => handleActions('remove')}>
                            <Typography textAlign="center">{'remove'}</Typography>
                        </MenuItem>
                   ]
                 )
            }
        }
        console.log('in all false');
        return (
           []
        )
    }
    const handleActions = async (option) => {
        console.log(option, 'options');
        console.log(props.portfolioData.portfolio_id, 'port id')
        if (option === 'edit') {
            navigate('/edit_portfolio/fund/'+props.portfolioData.portfolio_id)
        }
        const formData = {
            portfolio_id : props.portfolioData.portfolio_id,
            action : option,
            user_type : props.userType 
        }
        try {
            const res = await axios.post('http://localhost:5000/portfolio/change_portfolio_status', formData)
            toast.success(res.data.message)
            setOptionsAnchorEl(null);
            props.setReload(!props.reload)
        } catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
            // event.preventDefault();
            return
        }

    }

    const handleDetailPage = (event) => {
        const portfolio_id = event.target.getAttribute('data-id')
        navigate('/portfolio/'+props.userType+'/'+portfolio_id)
    }

    return (
        <>
            <Card key={props.index} sx={{ width: 300, height:300, display:'flex', flexDirection:'column', justifyContent:'space-between', border: props.portfolioData.status === true ? '1px solid green': '1px solid blue' }}>
                <CardHeader sx={{height: 30, fontStyle: 'bold',color: props.portfolioData.status === true ? 'green': 'blue'}} action={<IconButton aria-label="portfolio-settings" aria-controls="options-menu" aria-haspopup="true" onClick={handleOptionsMenuClick}><MoreVertIcon></MoreVertIcon></IconButton>} avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">{props.portfolioData.portfolio_name.split(" ")[0][0]}</Avatar>} title={props.portfolioData.portfolio_name} subheader={"Equity . "+props.portfolioData.risk_profile} />
                <Menu id="options-menu" sx={{fontSize:10}} anchorEl={optionsAnchorEl} open={optionsOpen} onClose={handleOptionsMenuClose} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} >
                    {getOptions(props)}
                        {/* { props.portfolioData.published_status === true ? 
                            <MenuItem key ='un-publish' onClick={() => handleActions('un-publish')}>
                                <Typography textAlign="center">{'un-publish'}</Typography>
                            </MenuItem> :
                            <MenuItem key='publish' onClick={() => handleActions('publish')}>
                                <Typography textAlign="center">{'publish'}</Typography>
                            </MenuItem> 
                        }       
                        <MenuItem key='edit' onClick={() => handleActions('edit')}>
                            <Typography textAlign="center">{'edit'}</Typography>
                        </MenuItem> 
                        <MenuItem key='delete' onClick={() => handleActions('delete')}>
                            <Typography textAlign="center">{'delete'}</Typography>
                        </MenuItem> 
                        : */}

                </Menu>
                <CardContent>
                    <Box width={'100%'} height={'60px'} display={'grid'} gridTemplateColumns={'auto auto auto auto'}>
                        <div>
                            <Typography variant="caption">Live Return</Typography>
                            <Typography>{props.portfolioData.live_return+"%"}</Typography>
                        </div>
                        <div>
                            <Typography variant="caption">Rebalance</Typography>
                            <Typography>{props.portfolioData.rebalance.charAt(0).toUpperCase()+props.portfolioData.rebalance.slice(1)}</Typography>
                        </div>
                        <div>
                            <Typography variant="caption">Min Investment</Typography>
                            <Typography>{props.portfolioData.min_investment}</Typography>
                        </div>
                    </Box>
                    <Box width={'100%'} height={'60px'} display={'grid'} gridTemplateColumns={'auto auto auto auto'}>
                        <div>
                            <Typography variant="caption">Subscribers</Typography>
                            <Typography>{props.portfolioData.subscribed}</Typography>
                        </div>
                        <div>
                            <Typography variant="caption">AUM</Typography>
                            <Typography>{props.portfolioData.total_aum}</Typography>
                        </div>
                    </Box>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {props.portfolioData.description}
                    </Typography>
                </CardContent>
                <CardActions sx={{margin:'0 auto', justifyContent:'center', height:40}}>
                    <Button size="small" onClick={handleDetailPage} data-id={props.portfolioData.portfolio_id} value={props.portfolioData.portfolio_name}>Details</Button>
                </CardActions>
            </Card>
        </>
    )
    
}

export default PortfolioCard