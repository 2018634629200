import React from "react";
import { Box,Typography } from "@mui/material";

const RefundPolicy = () => {
    return (
        <>

        {/* <Box width={'100%'} display={'flex'} justifyContent={'center'}> */}
            {/* <Box width={'60%'} border={'1px solid lightgrey'} mt={2} p={2}>  */}
        <p class='title1'>Hedgeloop - Refund Policy</p>
        <div className='terms-box'>
                <br></br>
                <Typography variant="h4">Refund / Cancellation</Typography>
                <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                       Tyck is a brand name under Hedeloop Technologies Pvt Ltd  
                    All services offered by Hedgeloop technologies Pvt limited is subject to the terms and conditions and clauses mentioned in agreement signed in between client and Hedgeloop Advisory-Investment Adviser.
                </Typography>
                <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                    Incase of Amendment or termination of agreement, if the amendments are acceptable to both the parties the services would be continued as agreed. In case of termination of agreement by client or by adviser, we would refund 50% fees for the unexpired period and 50% fees would be charged as breakage fee, however a maximum breakage fee that would be levied would not be more than one quarter. In case of termination of contract we would guide client for exiting his investment which he has done as per our advise.

                </Typography>
                <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                    All the recommendations/advice are given to you based on our judgments and the information available on particular point of time. However Directors, Technicians or employees will not be responsible for the losses or gains made through the calls. Clients are advised to exercise the tips at their own risk.
                </Typography>
                <Typography variant="body1" sx={{fontSize: '18px', lineHeight: 1.5, letterSpacing: '0.05em', textAlign: 'justify'}} m={2}>
                    If you have any questions about our service please, email us at support@tyck.in
                </Typography>

            {/* </Box>

        </Box> */}
        </div>
        </>
    )
}

export default RefundPolicy