import React, { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import './index.css'
import myLogo from './Tyck-logo-feb-13.svg'
import { toast } from 'react-toastify';
import axios from "axios";
import validator from 'validator';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import myLocalStorage from '../utils/shared_utils';
// import {useNavigate} from 'react-router-dom';


const steps = [
    'Email Verification',
    'Terms and Conditions',
    'User Verification',
    'Risk Profiling',
    'Curated Portfolios'
  ];

function EmailVerify(props){

    // const navigate = useNavigate();

    const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [emailId, setEmailid] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);

    const handleClose = () => {
        setIsDataLoading(false);
    };
    const onFormSubmit = async () => {
        setIsDataLoading(true);
        const formData = {
            'email_id' : document.getElementById('email_id').value
        }
        if (formData.email_id.length === 0 ) {
            toast.error('Email Field is Empty')
            return;
        }
        if (!validator.isEmail(formData.email_id)){
            toast.error('Not a Valid Email, Please Provide a Proper Email');
            return
        }
        try {
            const res = await axios.get("http://localhost:5000/userRegister/get_user_status?email_id="+formData.email_id);
            // const res = await axios.get("http://localhost:5000/userRegister/get_user_status",formData);
            const status = res.data
            console.log(res.data, 'status bto')
            if (status === 'email_verified'){
                props.nextStep();
                toast.success('Welcome Back!!!')
                return
            } else if (status === 'terms_verified'){
                props.customStep(3)
                toast.success('Welcome Back!!!')
                return
            } else if (status === 'user_verified'){
                props.customStep(4)
                toast.success('Welcome Back!!!')
                return
            } else if (status === 'rp_verified'){
                props.customStep(5)
                toast.success('Welcome Back!!!')
                return
            } else if (status === 'full_verified'){
                // navigate('/dashboard')
                toast.success("User Already Finished Register Process, Please continue to Dashboard")
                return
            }
        } catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
                return
            } else {
                toast.error(err.response.data.message);
            }
        }
        try {
            await axios.post("http://localhost:5000/userRegister/verify_email?email_id="+formData.email_id);
            toast.success('OTP Sent Successfully')
            setIsVerificationEmailSent(true);
            setEmailid(formData.email_id);
        } catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
        }
        setIsDataLoading(false);
    }

    const onOtpSubmit = async () => {
        setIsDataLoading(true)
        const formData = {
            otp: document.getElementById("otp").value,
            email_id: emailId
        }
        if (!formData.otp.length){
            toast.error('OTP Field is Empty')
            return
        }
        if (!formData.otp.length === 6){
            toast.error('Not a Valid OTP')
            return
        }
        
        try {
            await axios.get("http://localhost:5000/userRegister/verify_email_otp?email_id="+formData.email_id+"&otp="+formData.otp);
            setIsEmailVerified(true);
            toast.success('Email Validated Successfully');
            myLocalStorage.setItem('email_id', formData.email_id)
            props.nextStep();
        } catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
        }
        setIsDataLoading(false);
    }
    return (
        <>
        {isDataLoading?
        <Backdrop sx={(theme) => ({ color: '#000', zIndex: theme.zIndex.drawer + 1, backgroundColor:'#fff' })} invisible={'true'} open={isDataLoading} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop> :
        <div>
            <Box sx={{ width: '80%'}} mt={10} ml={15}>
                <Stepper activeStep={0} alternativeLabel>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </Box>
            <div className='data-box'>
            <Box width={'30%'} mt={10} className='email-verify-box'>
                <div class="logo-container">
                    <img src={myLogo} className="App-logo" alt="logo" />
                </div>
                {isVerificationEmailSent ?"":
                <div className="emailverifyblock">
                    <TextField required id="email_id" label="Email Address" size="small" />
                    <br/><br/>
                    <Button variant="contained" size="small" onClick={onFormSubmit}>Get OTP</Button>
                </div> }
                {isVerificationEmailSent ?
                <div className="emailotpverifyblock">
                    <TextField required id="otp" label="Email OTP" size="small" />
                    <br/><br/>
                    <Button variant="contained" size="small" onClick={onOtpSubmit}>Submit OTP</Button>
                </div> : ""
            }
            </Box>
            </div>
            </div>
}
     </>
    )
}

export default EmailVerify