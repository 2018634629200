import { Box, Stack, Typography } from "@mui/material";

const PortfolioEvaluatorsData = (props) => {
    return (
        <Box width={'30%'} p={2} height={350} sx={{boxShadow: '0px 0px 10px #888888'}}>
            <Stack gap={4} pl={2}>
                    <Stack gap={6} direction={'row'}>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Max Equity</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.max_equity}</Typography>
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>MDD</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.mdd}</Typography>
                        </Stack>
                    </Stack>
                    <Stack gap={6} direction={'row'}>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600, fontSize: 18}}>Subscribed</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.subscribed_date}</Typography>
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Last Rebalance</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.last_rebalanced_date}</Typography>
                        </Stack>
                    </Stack>
            </Stack>
        </Box>
    )
}

export const FundPortfolioEvaluatorsData = (props) => {
    return (
        <Box width={'30%'} p={2} borderRadius={2} height={350} sx={{boxShadow: '0px 0px 10px #888888'}}>
            <Stack gap={4} pl={2}>
                    <Stack gap={6} direction={'row'}>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Max Equity</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>0</Typography>
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>MDD</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>0</Typography>
                        </Stack>
                    </Stack>
                    <Stack gap={6} direction={'row'}>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600, fontSize: 18}}>Total Subscribed</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.total_subscribed}</Typography>
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total AUM</Typography>
                            <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.total_aum}</Typography>
                        </Stack>
                    </Stack>
            </Stack>
        </Box>
    )
}


export default PortfolioEvaluatorsData