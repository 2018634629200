import { useEffect } from "react";
import Chart from "react-apexcharts";

const DrawCharts = (props) => {

    useEffect(() => {
        // console.log(props.seriesData, 'series data')
        // console.log(props.labelData, 'label data')
        console.log('Working on Graphs');
        
    },[])
    const liveChartOptions = {
        options: {
            dataLabels: {
                enabled: false
            },
            chart: {
                id: 'line_chart',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: false,
                        zoomout: false,
                        zoomin: false,
                        zoom: false,
                        pan:false,
                        reset: false,
                        customIcons: [{
                            icon: '<Image src="/images/barChartIcon.png" alt="bar" width="18px" height="18px"></Image>',
                            index: 0,
                            title: 'Bar Chart',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                props.setChartType('bar');
                              }
                        },
                        {
                            icon: '<Image src="/images/lineChartIcon.png" alt="line" style="margin-left:5px;" width="18px" height="17px"></Image>',
                            index: 1,
                            title: 'Line Chart',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                console.log("clicked custom-icon")
                                props.setChartType('line');
                              }
                        },
                        {
                            icon: '<Image src="/images/areaChartIcon.svg" alt="area" style="margin-left:5px;" width="20px" height="20px"></Image>',
                            index: 2,
                            title: 'Area Chart',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                console.log("clicked custom-icon")
                                props.setChartType('area');
                              }
                        }]
                    }
                }
            },
            xaxis: {
                categories: props.labelData
            },
        }
    }

    const backtestChartOptions = {
        options: {
            dataLabels: {
                enabled: false
            },
            chart: {
                id: 'line_chart',
                toolbar: {
                    show: true,
                    offsetX: -100,
                    offsetY: 0,
                    tools: {
                        download: false,
                        zoomout: false,
                        zoomin: false,
                        zoom: false,
                        pan:false,
                        reset: false,
                        customIcons: [{
                            icon: '<Image src="/images/barChartIcon.png" alt="bar" width="18px" height="18px"></Image>',
                            index: 0,
                            title: 'Bar Chart',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                props.setChartType('bar');
                              }
                        },
                        {
                            icon: '<Image src="/images/lineChartIcon.png" alt="line" style="margin-left:5px;" width="18px" height="17px"></Image>',
                            index: 1,
                            title: 'Line Chart',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                console.log("clicked custom-icon")
                                props.setChartType('line');
                              }
                        },
                        {
                            icon: '<Image src="/images/areaChartIcon.svg" alt="area" style="margin-left:5px;" width="20px" height="20px"></Image>',
                            index: 2,
                            title: 'Area Chart',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                console.log("clicked custom-icon")
                                props.setChartType('area');
                              }
                        },
                        {
                            icon: '<strong style="margin-left:5px">1M</strong>',
                            index: 3,
                            title: 'Monthly Grpah',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                console.log('')
                                // props.updateGraphData('monthly')
                                props.setBacktestDuration('monthly')
                              }
                        },
                        {
                            icon: '<strong style="margin-left:10px">3M</strong>',
                            index: 4,
                            title: '3 Months Data',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                // props.updateGraphData('quarterly')
                                props.setBacktestDuration('quarterly')
                              }
                        },
                        {
                            icon: '<strong style="margin-left:15px">6M</strong>',
                            index: 5,
                            title: '6 Months Data',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                // props.updateGraphData('half_yearly')
                                props.setBacktestDuration('half_yearly')

                              }
                        },
                        {
                            icon: '<strong style="margin-left:20px">1Y</strong>',
                            index: 6,
                            title: '1 Year Data',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                // props.updateGraphData('yearly')
                                props.setBacktestDuration('yearly')
                            }
                        },
                        {
                            icon: '<strong style="margin-left:25px">5Y</strong>',
                            index: 7,
                            title: '5 Year Data',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                // props.updateGraphData('five_yearly')
                                props.setBacktestDuration('five_yearly')
                            }
                        },
                        {
                            icon: '<strong style="margin-left:30px">MAX</strong>',
                            index: 8,
                            title: 'Max Data',
                            class: 'custom-icon',
                            click: function (chart, options, e) {
                                // props.updateGraphData('max')
                                props.setBacktestDuration('max')
                              }
                        },
                        {
                            icon: '<Image src="/images/menuImage.png" alt="menu" style="margin-left:50px;" width="20px" height="20px"></Image>',
                            index: 9,
                            title: 'Parameters',
                            class: 'custom-icon',
                            click: props.handleMenuClick
                        }]
                    }
                }
            },
            xaxis: {
                categories: props.labelData
            },
        }

    }
    return (
        <>
        { props.graphType == 'backtest' ?
            <div style={{ position: 'relative' }}>
               <Chart height={300} id='backtestGraph' series={props.seriesData} type={props.liveChartType} options={backtestChartOptions.options} sx={{width:'100%'}}></Chart>
                {props.DropDownVisible && (
                    <div className="dropdown-menu">
                        <div onClick={() => props.handleDropdownItemClick('open')} className="dropdown-item">Open</div>
                        <div onClick={() => props.handleDropdownItemClick('pnl')} className="dropdown-item">PNL</div>
                        <div onClick={() => props.handleDropdownItemClick('beta')} className="dropdown-item">Beta</div>
                    </div>
                )}
            </div> : 
            <div style={{ position: 'relative' }}>
                <Chart height={300} id='pnlGraph' series={props.seriesData} type={props.backtestChartType} options={liveChartOptions.options} sx={{width:'100%'}}></Chart>
                {props.DropDownVisible && (
                    <div className="dropdown-menu">
                        <div onClick={() => props.handleBacktestDropdownItemClick('pnl')} className="dropdown-item">PNL</div></div>
                )}
            </div>
        }
        </>
    )
}

export default DrawCharts