import React, { useEffect, useState } from "react";
import { Paper, Box, FormControl, Select, TextField, MenuItem, Button, Typography, Grid2, Modal, Stack, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";


const coverageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    height: 200,
  };

  const coverageStyle2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    height: 100,
  };

const PortfolioEditMetaInputs = (props) => {

    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [minInvestment, setMinInvestment] = useState(0);
    const [portfolioActionOpen, setPortfolioActionOpen] = useState(false);

    const handlePortfolioCoverageOpen = () => {
        setOpen(true);
    }
    const handlePortfolioCoverageClose = () => {
        setOpen(false);
    }
    const handlePortfolioCoverageSubmit = () => {
        setOpen(false);
    }
    const showPortfolioCoverage = () => {
        console.log('Open Portfolio Coverage')
        setOpen(true);
    }
    const handlePortfolioActionClose = () => {
        
        setPortfolioActionOpen(false);
    }

    const handleDeleteStock = (event) => {
        console.log(event.currentTarget.value, 'value bro')
        const newValue = props.metaData.selected_stocks.filter(item => item.symbol !== event.currentTarget.value)
        props.updateMetaData({['selected_stocks']: newValue})
    }
    const handlePortfolioActionOpen = () => {
        const portfolio_name = document.getElementById('portfolio_name').value;
        if (!portfolio_name){
            toast.error('Please provide Portfolio Name')
            return
        }
        // const portfolio_coverage = document.getElementById('portfolio_coverage').value;
        const portfolio_coverage = props.metaData.portfolio_coverage
        if (!portfolio_coverage){
            toast.error('Please Provide Portfolio Coverage')
            return
        }
        setPortfolioActionOpen(true);
    }
    const calMinInvestment = async() => {
        const symbolElementsList = document.getElementsByName('symbol_name')
        const qtyElementsList = document.getElementsByName('symbol_quantity')
        const priceElementsList = document.getElementsByName('symbol_price')
        var minv = 0
        for (var i=0; i<symbolElementsList.length; i++){
            // console.log(priceElementsList[i].value, 'price')
            // console.log(qtyElementsList[i].value, 'qty')
            const val = parseFloat(priceElementsList[i].value) * parseInt(qtyElementsList[i].value)
            console.log(val, 'value bto')
            minv = (minv + val).toFixed()
        }
        // setMinInvestment(minv)
        // console.log('Min Investment', minv);
        props.metaData.min_investment = minv;
    }
    const handleRebalance = () =>{
        toast.success('Portfolio Rebalanced Successfully')
        navigate('/dashboard/fund/2')
    }
    // useEffect(()=>{
    //     calMinInvestment()
    // },[])
    // const calMinInvestment = () => {
    //     console.log('caluclate Investment')
    // }
    return (
        <>
            <Paper sx={(theme) => ({justifyContent:'center', height: 580, width: 400, backgroundColor: '#fff', ...theme.applyStyles('dark', {backgroundColor: '#1A2027',}), overflowY: 'auto', overflowX:'hidden'})}>
                <Box width={'100%'} height={70} p={2}>
                    <FormControl variant='filled'>
                        <label for='portfolio_name'>Portfolio Name:</label>
                        <TextField margin="dense" sx={{width:300}} variant='outlined' name='portfolio_name' value={props.metaData.portfolio_name} id='portfolio_name' placeholder='Portfolio Name' slotProps={{inputLabel: {shrink: false}}} size='small' readOnly></TextField>
                    </FormControl>
                </Box>
                <Box width={'100%'} height={70} pl={2}>
                    <FormControl variant="outlined">
                        <label for='risk_profile'>Risk Profile</label>
                        <Select labelId='risk_profile_label' name='risk_profile' id='risk_profile' variant="outlined" sx={{width:'300px'}} size='small' value={props.metaData.risk_profile} readOnly >
                            <MenuItem value={'very_conservative'}>Very Conservative</MenuItem>
                            <MenuItem value={'conservative'}>Conservative</MenuItem>
                            <MenuItem value={'moderate'}>Moderate</MenuItem>
                            <MenuItem value={'aggressive'}>Aggressive</MenuItem>
                            <MenuItem value={'very_aggressive'}>Very Aggressive</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box width={'100%'} height={70} pl={2}>
                    <FormControl variant='filled'>
                        <label for='min_investment'>Min Investment:</label>
                        <TextField margin="dense" sx={{width:300}} variant='outlined' id='min_investment' name='min_investment' placeholder='Min Investment' size='small' slotProps={{ inputLabel: {shrink: false}}} value={props.metaData.investment} onChange={props.onInputChange} required></TextField>
                    </FormControl>
                </Box>
                <Box width={'100%'} height={70} pl={2}>
                    <FormControl variant="outlined">
                        <label for='rebalance'>Rebalance</label>
                        <Select labelId='rebalance_label' id='rebalance' name='rebalance' variant="outlined" sx={{width:'300px'}} size='small' value={props.metaData.rebalance} onChange={props.onInputChange} >
                            <MenuItem value={'weekly'}>Weekly</MenuItem>
                            <MenuItem value={'monthly'}>Monthly</MenuItem>
                            <MenuItem value={'quarterly'}>Quarterly</MenuItem>
                            <MenuItem value={'yearly'}>Yearly</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box width={'100%'} height={70} pl={2}>
                    <FormControl variant="outlined">
                        <label for='rebalance'>Portfolio Coverage</label>
                        <Button size='small' variant="contained"  onClick={showPortfolioCoverage}>Add Portfolio Coverage</Button>
                    </FormControl>
                </Box>
                <Box width={'100%'} p={1}>
                    <Grid2 container justifyContent={'flex-end'} pr={5}>
                        {/* <Button size='small' variant="contained" onClick={handlePortfolioCoverageOpen}>Add Portfolio Coverage</Button> */}
                        <Typography>W&beta; : {props.metaData.weighted_beta}</Typography>
                    </Grid2>        
                </Box>
                <Box id='selected_stocks_data' width={'90%'} height={250} ml={2} mt={1} sx={{border: '1px solid grey', borderRadius:2, overflowY:'auto', overflowX:'hidden'}}>
                    {props.metaData.selected_stocks.map(elem => {
                        console.log(elem.symbol, 'eme');
                        
                        return(
                            <Box display={'flex'} gap={2} p={2}>
                                <FormControl width={'40%'}>
                                    <TextField size='small' name="symbol_name" label="Symbol" value={elem.symbol} slotProps={{input: {readOnly: true,},}}/>
                                </FormControl>
                                <FormControl>
                                    <TextField size='small' name="symbol_quantity" label="Quantity" defaultValue={1} onChange={calMinInvestment} type="number"/>
                                </FormControl>
                                <FormControl>
                                    <IconButton value={elem.symbol} onClick={handleDeleteStock}>
                                        <DeleteIcon />
                                    </IconButton>
                                </FormControl>
                                <FormControl>
                                    <TextField variant="standard" size='small' name="symbol_price" value={elem.price} type="hidden"/>
                                </FormControl>
                                <FormControl>
                                    <TextField variant="standard" size='small' name="symbol_coverage" value={elem.coverage} type="hidden"/>
                                </FormControl>
                            </Box>
                        )

                    })}
                </Box>
                <Box width={'100%'} p={1} mb={2}>
                    <Grid2 container ml={10}>
                        <Button size='medium' variant="contained" onClick={handleRebalance}>Create Portfolio</Button>
                    </Grid2> 
                </Box>
            </Paper>
            <Modal open={open} onClose={handlePortfolioCoverageClose} aria-labelledby="portfolio-coverage-modal" aria-describedby="portfolio-coverage-modal-description">
                <Box sx={coverageStyle}>
                    <TextField id='portfolioCoverage' name='portfolio_coverage' onChange={props.onInputChange} value={props.metaData.portfolio_coverage} label='Portfolio Coverage' multiline rows={4} fullWidth></TextField>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <Button variant="contained" float='right' size='small' onClick={handlePortfolioCoverageSubmit}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
            <Modal open={portfolioActionOpen} onClose={handlePortfolioActionClose} aria-labelledby="portfolio-action-modal" aria-describedby="portfolio-action-modal-description">
                <Box sx={coverageStyle2}>
                    <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                        <Button variant="outlined" sx={{width: '30%', height: 100}} type='button' value='save' onClick={props.onSubmit}>Save</Button>
                        <Button variant="outlined" sx={{width: '30%', height: 100}} type='button' value='publish' onClick={props.onSubmit}>Publish</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default PortfolioEditMetaInputs