import React, { useContext, useEffect, useState, useReducer, useRef, memo }  from "react";
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, Stack } from "@mui/material";
import HelpPopover from "../common/coveragePopover";
import { WebSocketContext, parseLiveBinary } from "../common/webSocketContext";

const PortfolioStockTable = memo((props) => {

    const socket = useContext(WebSocketContext);
    const [message, setMessage] = useState('');
    
    let pids = []
    props.stocksTableData.forEach((item) => {
        pids.push(item.pair_id)
    })
    if (socket && socket.readyState === WebSocket.OPEN) {
        const subscribeMessage = {
            a: 'subscribe',
            v: pids, // Example instrument tokens
        };
    
        socket.send(JSON.stringify(subscribeMessage));
        // socket.send(JSON.stringify({a: 'mode', v: ['full', pids]}))
        // socket.send('Hello from MyComponent!');
        console.log('Pid has been subscribed ', pids)
    } else {
        console.log('WebSocket is not open');
    }
    useEffect(() => {
        if (!socket) return;

        const handleMessage = (event) => {
            if (event.data instanceof ArrayBuffer){
                const d = parseLiveBinary(event.data)
                if (d) {
                    console.log('Received message in MyComponent:', d);
                    // updateTablecell(rowId, 4, d['last_price'])
                    d.forEach((item) => {
                        const rowId = item['instrument_token']
                        const newValue = item['last_price']

                        props.updateTablecell(rowId, 'exit', newValue);
                    })
                }
            }
            setMessage(event.data);  // Update the state with the incoming message
        };

        socket.addEventListener('message', handleMessage);

        // Cleanup the event listener on component unmount
        return () => {
            socket.removeEventListener('message', handleMessage);
        };
    },[socket])
    return (
        <>
            <TableContainer component={Paper} sx={{minHeight:200}}>
                <Table stickyHeader sx={{minWidth: 650}} aria-label='stocks table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Stock Name</TableCell>
                            <TableCell align="right">Allocation</TableCell>
                            <TableCell align="right">Investment</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Entry</TableCell>
                            <TableCell align="right">Exit</TableCell>
                            <TableCell align="right">&beta;</TableCell>
                            <TableCell align="right">PNL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.stocksTableData.map((key, index) => (
                            <TableRow key={key.pair_id}>
                                <TableCell><Stack direction={'row'}>{key.symbol} <HelpPopover coverage={key.stock_coverage}/></Stack></TableCell>
                                <TableCell align='right'>{key['allocation']}</TableCell>
                                <TableCell align='right'>{key['investment']}</TableCell>
                                <TableCell align="right">{key['quantity']}</TableCell>
                                <TableCell align="right">{key['entry']}</TableCell>
                                <TableCell align="right">{key['exit']}</TableCell>
                                <TableCell align="right">{(key['beta']/100).toFixed(3)}</TableCell>
                                <TableCell align="right">{(key['exit'] - key['entry']).toFixed(2)}</TableCell> 
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
})

export default PortfolioStockTable