import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Typography } from "@mui/material";
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';
import {blue} from '@mui/material/colors';
import FormControl from "@mui/material/FormControl";
import ReactSelect from 'react-select';
import IconButton from '@mui/material/IconButton';
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from 'react-router-dom';
import myLocalStorage from '../../utils/shared_utils';


const Header = (props) => {

    const navigate = useNavigate();

    const [options, setOptions] = useState([]);
    const [asset, setAsset] = useState('equity');
    const [instrData, setInstrData] = useState([]);
    const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
    const [anchorElUser, setAnchorElUser] = useState(null);
    

    const handleRedirect = () => {
        navigate('/dashboard/'+props.userType+'/'+props.userId)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (key) => {
        if (key == 'Logout'){
            myLocalStorage.removeItem('accessToken')
            navigate('/')
        }

        setAnchorElUser(null);
    };

    const getOptions = async(assetValue) => {
        if (!assetValue === 'equity'){
            setAsset(assetValue);
            toast.error('Supported Assets are Equity Only, Please choose the same')
            return;
        }
        const formData = {
            asset : assetValue
        }
        const res = await axios.get('http://127.0.0.1:5000/portfolio/get_symbols',{params: formData})
        const symbol_list = res.data.symbols.map((ele, index) => ({
            value: ele, label: ele 
        }))
        setInstrData(res.data.instr_data);
        setOptions(symbol_list)
        setAsset(assetValue);
    }
    useEffect(()=>{
        if (props.stocksList){
            getOptions('equity');
        }
    }, [props.stocksList])

    return (
        <>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography variant="h6" noWrap component="a" onClick={handleRedirect} sx={{mr: 2,display: { xs: 'none', md: 'flex' },fontFamily: 'monospace',fontWeight: 700,letterSpacing: '.3rem',color: 'inherit',textDecoration: 'none',}}> TYCK </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} ml={'20%'}>
                            { props.stocksList ? 
                                <FormControl variant="outlined" sx={{width: '60%'}}>
                                    <ReactSelect id='stock_name' name='stock_name' options={options} onChange={props.handleChange} ></ReactSelect>
                                </FormControl>
                                : ''
                            }
                        </Box>
                        <Box sx={{ flexGrow: 0 }} align='right' crossAlign='stretch'>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="TYCK" sx={{ bgcolor: blue[500]}}>T</Avatar>
                                </IconButton>
                            </Tooltip>
                            <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{vertical: 'top',horizontal: 'right',}} keepMounted transformOrigin={{vertical: 'top',horizontal: 'right',}} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default Header