import React from "react";
import { useRoutes } from 'react-router-dom';
import Login from "./containers/login/login";
import Dashboard from "./containers/dashboard/dashboard";
import PortfolioDetails from "./containers/portfolio/portfolioDetails";
import NewPortfolio from "./containers/portfolio/newPortfolio";
import EditPortfolio from "./containers/portfolio/editPortfolio";
import Register from './registerFlow'
import RefundPolicy from "./containers/policies/refundPolicy";
import PrivacyPolicy from "./containers/policies/privacyPolicy";
import TermsConditions from "./containers/policies/termsConditions";

// import { WebSocketProvider } from "./components/common/webSocketContext";

const AppRoutes = () => {
    let routes = useRoutes([
        {path : '/', element: <Login></Login>},
        {path : '/register', element: <Register></Register>},
        {path : '/dashboard/:type/:userId', element: <Dashboard/>},
        {path : '/portfolio/:type/:id', element : <PortfolioDetails/>},
        {path : '/new_portfolio/:type/:userId', element: <NewPortfolio/>},
        {path : '/edit_portfolio/:type/:id', element: <EditPortfolio />},
        {path : '/refund_policy', element: <RefundPolicy/>},
        {path : '/privacy_policy', element: <PrivacyPolicy/>},
        {path: '/terms_and_conditions', element: <TermsConditions/>},
        {path: '*', element: <Login></Login>}
    ])
    return routes;
}

export default AppRoutes