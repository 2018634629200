import { Box, Stack, Typography, Button, Modal } from "@mui/material";
import HelpPopover from "../common/coveragePopover";
import { useEffect, useState } from "react";
import KiteOffsiteButton from "../common/kiteOffsetButton";
// import '../index.css'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

const PortfolioMetaData = (props) => {

    const [brokerOpen, setBrokerOpen] = useState(false);

    const handleBrokerClose =() => {
        setBrokerOpen(false);
    }

    const handleBrokerOpen = () => {
        setBrokerOpen(true)
    }

    useEffect(() => {
        console.log(props.metaData, 'meta data')
    },[])

    return (
        <Box width={'96%'} ml={3} mt={2} height={100} sx={{boxShadow: '0px 0px 10px #888888', borderRadius:2}}>
                {/* <Stack direction={'row'} gap={5} p={2}> */}
            <Stack ml={3} direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 10 }} p={2} useFlexGap sx={{ flexWrap: 'wrap' }}>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Portfolio Name</Typography>
                    <Stack direction={'row'}>
                        <Typography component={'span'} variant="subtitle" justifyContent={'center'} id='portfolio_name'>{props.metaData.portfolio_name}</Typography>
                        <HelpPopover coverage={props.metaData.portfolio_coverage}></HelpPopover>
                    </Stack>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Investment</Typography>
                    <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.investment}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total PNL</Typography>
                    <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.total_pnl}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>M to M</Typography>
                    <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.MTOM}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Weighted Beta</Typography>
                    <Typography component={'span'} variant="subtitle" justifyContent={'center'}>{props.metaData.weighted_beta}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Button variant='contained' onClick={handleBrokerOpen}>Execute Portfolio</Button>
                </Stack>
            </Stack>
            <Modal open={brokerOpen} onClose={handleBrokerClose} aria-labelledby="execute-modal-title" aria-describedby="execute-modal-description">
                <Box sx={style}>
                    <Box sx={{width:'100%', height:100}}>
                        <Typography variant="subtitle">Login to your Broker</Typography>
                    </Box>
                    <Box sx={{width:'100%'}}>
                        <Stack direction={'row'}>
                            <KiteOffsiteButton portfolio_id={props.portfolio_id}></KiteOffsiteButton>
                            {/* <Button variant="contained" id='kite-login' sx={{ display: 'flex', flexDirection:'column',alignItems: 'center', backgroundColor: 'white', color: 'black', padding: '10px 20px', '&:hover': { color: '#ff5722', borderColor:'#ff5722'},border: '1px solid black' }} >
                                <img src={kiteLogo} alt="logo" style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                <Typography variant="button">Zerodha</Typography>
                            </Button> */}
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default PortfolioMetaData