import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import myLogo from './Tyck-logo-feb-13.svg';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import CheckboxButton from './checkboxButton';


function SubscribeFlow(props) {

    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const [plan, setPlan] = useState('');
    // const [signed, setSi]
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleConfirm = (event) => {
        setPlan(event.target.value)
        setExpanded(false)
    }
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    const handleSubscribe = () => {
        setIsSigned(true)
        setOpen(false)
    }
    const handleFinalSubmit = async() => {
        // props.nextStep();
        if (isSigned && plan){
            const formData = {
                // 'portfolio_id' : props.portfolio_id,
                'portfolio_id' : 9,
                'user_id': 12
            }
            console.log(formData)
            try {
                const res = await axios.post('http://localhost:5000/portfolio/subscribe_curated_portfolio', formData)
                toast.success('Registration Process Completed Successfully');
                navigate('/dashboard/user/12')
            } catch(err){
                if (!err.response){
                    console.log(err)
                    toast.error(err.message)
                } else {
                    toast.error(err.response.data.message);
                }
            }
        } else {
            toast.error('Please Select Plan and Sign the Agreement')
            return
        }
    }

    const [is1PlanChecked, setIs1PlanChecked] = useState(false);
    const [is3PlanChecked, setIs3PlanChecked] = useState(false);
    const [is6PlanChecked, setIs6PlanChecked] = useState(false);

    // useEffect(()=> {
    //     console.log('Plan Selected')
    // },[setIs1PlanChecked, setIs3PlanChecked, setIs6PlanChecked])
  const handle1PlanCheckboxChange = () => {
    setIs1PlanChecked(true);
    setIs3PlanChecked(false);
    setIs6PlanChecked(false);
  };
  const handle3PlanCheckboxChange = () => {
    setIs1PlanChecked(false);
    setIs3PlanChecked(true);
    setIs6PlanChecked(false);
  };
  const handle6PlanCheckboxChange = () => {
    setIs1PlanChecked(false);
    setIs3PlanChecked(false);
    setIs6PlanChecked(true);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
    return (
        <div>
            <Accordion style={{marginTop:10}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={plan?<CheckCircleIcon sx={{color:'green'}}></CheckCircleIcon>:<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography>Select Plan</Typography>
                    {plan?
                    <Typography ml={1} sx={{ textTransform:'uppercase'}}>( {plan} )</Typography> :""
                    }
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Box> */}
                    <FormControl>
                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Plan</FormLabel> */}
                        {/* <Typography variant='subtitle1' mb={2}>Select a Plan</Typography> */}
                        <FormLabel component="legend">Select a Plan</FormLabel>
                        <RadioGroup row aria-label="options" name="customized-radios" value={plan} onChange={handleConfirm}>
                            <FormControlLabel value="1" control={<Radio sx={{display:'none'}} />} label={<label><p>1 Month</p><p>800 Rs/mo</p></label>} sx={{ backgroundColor: 'transparent', color: plan === '1'? 'green':'#000', borderRadius: 2, padding: '8px 16px', margin: 1, '&:hover': {color: 'green', border:'1px solid green' }, border: plan === '1'? '1px solid green':'1px solid grey'}}/>
                            <FormControlLabel value="3" control={<Radio sx={{display:'none'}}/>} label={<label><p>3 Months</p><p>2200 Rs/mo</p></label>} sx={{ backgroundColor: 'transparent', color: plan === '3' ? 'green' : '#000', borderRadius: 2, padding: '8px 16px', margin: 1, '&:hover': { color: 'green', border:'1px solid green'}, border: plan === '3'? '1px solid green':'1px solid grey'}}/>
                            <FormControlLabel value="6" control={<Radio sx={{display:'none'}}/>} label={<label><p>6 Months</p><p>4500 Rs/mo</p></label>} sx={{ backgroundColor: 'transparent', color: plan === '6' ? 'green' : '#000', borderRadius: 2, padding: '8px 16px', margin: 1, '&:hover': { color: 'green', border: '1px solid green' }, border: plan === '6'? '1px solid green':'1px solid grey'}} />
                        </RadioGroup>
                        {/* <RadioGroup row aria-labelledby="plan-group-label" name="plan-group" onChange={handleConfirm}>
                            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                            <FormControlLabel value="quarterly" control={<Radio />} label="Quarterly" />
                            <FormControlLabel value="halfyearly" control={<Radio />} label="Half Yearly" />
                            <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                        </RadioGroup> */}
                        {/* <Box width={'90%'} border={'1px solid grey'} p={2} >
                            <Typography variant='subtitle1' mb={2}>Select a Plan</Typography>
                            <Stack gap={2} direction={'row'}>
                                <CheckboxButton checked={is1PlanChecked} onChange={handle1PlanCheckboxChange} plan={'1 month'} amount={'800 Rs/m'}></CheckboxButton>
                                <CheckboxButton checked={is3PlanChecked} onChange={handle3PlanCheckboxChange} plan={'3 month'} amount={'2200 Rs/m'}></CheckboxButton>
                                <CheckboxButton checked={is6PlanChecked} onChange={handle6PlanCheckboxChange} plan={'6 month'} amount={'4200 Rs/m'}></CheckboxButton>
                            </Stack>
                        </Box> */}
                    </FormControl>
                    {/* <Button size="small" onClick={handleConfirm} sx={{float: 'right'}}>Confirm</Button> */}
                    {/* </Box> */}
                </AccordionDetails>
            </Accordion>
            {/* <Accordion style={{marginTop:10}} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}> */}
            <Accordion style={{marginTop:10}} expanded={expanded === 'panel2'} onChange={handleClickOpen('paper')}>
                <AccordionSummary expandIcon={isSigned? <CheckCircleIcon sx={{color:'green'}}></CheckCircleIcon>:<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                    <Typography>Sign Agreement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Box> */}
                    {/* <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Plan</FormLabel>
                        <RadioGroup row aria-labelledby="plan-group-label" name="plan-group" onChange={handleConfirm}>
                            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                            <FormControlLabel value="quarterly" control={<Radio />} label="Quarterly" />
                            <FormControlLabel value="halfyearly" control={<Radio />} label="Half Yearly" />
                            <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                        </RadioGroup>
                    </FormControl> */}
                    {/* <Button size="small" onClick={handleConfirm} sx={{float: 'right'}}>Confirm</Button> */}
                    {/* </Box> */}
                </AccordionDetails>
            </Accordion>
            <Box mt={2} width={'100%'} display={'flex'} sx={{justifyContent: 'center'}}>
                <Button variant='contained' onClick={handleFinalSubmit}>Submit</Button>
            </Box>
            <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="agreement-title" aria-describedby="scroll-dialog-description" maxWidth='md' fullWidth='md'>
                <DialogTitle id="agreement-title" sx={{textAlign: 'center'}}>
                    <Typography>Investment Adviser Agreement</Typography>
                    <Typography>Aug 29 2024</Typography>
                </DialogTitle>
                {/* <DialogTitle id="agreement-title">Investment Adviser Agreement</DialogTitle> */}
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText id="agreement-description" ref={descriptionElementRef} tabIndex={-1}></DialogContentText>
                    <Box width={'100%'}>
                        <Box width={'100%'} sx={{margin:'0 auto', border:'1px solid #e6e5e5', borderRadius: 5}}>
                            <div className='agreement-header'>
                                <img width={150} height={50} src={myLogo} alt="TYCK"></img>
                            </div>
                            <div className='agreement-signatory-details'>
                                <div className='advisor-details-headers'>
                                    <Typography variant='h6' ml={2}>Advisor Details</Typography>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Name</Typography>
                                        <Typography ml={2} variant='body'>Hedgeloop Technologies Pvt Ltd.</Typography>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <Typography ml={2} variant='subtitle2'>SEBI Registration</Typography>
                                        <Typography ml={2} variant='body'>INA200007265</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <Typography ml={2} variant='subtitle2'>Email</Typography>
                                    <Typography ml={2} variant='body'>info@hedgeloop.com</Typography>
                                </div>
                                <Divider variant="middle" sx={{marginTop: 2, marginBottom:2}}/>
                                {/* <div className='agreement-signatory-details'> */}
                                <div className='advisor-details-headers'>
                                    <Typography variant='h6' ml={2}>Client Details</Typography>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Name</Typography>
                                        <Typography ml={2} variant='body'>Shyamsundhar Aadurthi</Typography>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <Typography ml={2} variant='subtitle2'>PAN</Typography>
                                        <Typography ml={2} variant='body'>ABCDE01234A</Typography>
                                    </div>
                                </div>
                                <div style={{display:'flex'}}>
                                    <div style={{marginTop: 10,width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Email</Typography>
                                        <Typography ml={2} variant='body'>shyam@hedgeloop.com</Typography>
                                    </div>
                                    <div style={{marginTop: 10,width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Age</Typography>
                                        <Typography ml={2} variant='body'>28 years</Typography>
                                    </div>
                                </div>
                                <Divider variant="middle" sx={{marginTop: 2, marginBottom:2}}/>
                                <div className='advisor-details-headers'>
                                    <Typography variant='h6' ml={2}>Subscription Details</Typography>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Portfolio Name</Typography>
                                        <Typography ml={2} variant='body'>{props.portfolio_name}</Typography>
                                    </div>
                                    <div style={{width: '50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Subscription Start Date</Typography>
                                        <Typography ml={2} variant='body'>{moment().format("DD MMM, YYYY")}</Typography>
                                    </div>
                                </div>
                                <div style={{display:'flex'}}>
                                    <div style={{marginTop: 10,width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Subscription Frequency</Typography>
                                        <Typography ml={2} variant='body' className='capitalize'>{plan}</Typography>
                                    </div>
                                    <div style={{marginTop: 10,width:'50%'}}>
                                        <Typography ml={2} variant='subtitle2'>Subscription Fee</Typography>
                                        <Typography ml={2} variant='body'>800/-</Typography>
                                    </div>
                                </div>
                                <Divider variant="middle" sx={{marginTop: 2, marginBottom:2}}/>
                                <div>
                                    <Typography variant='body2'>In this Agreement, unless the context otherwise requires, the Client and the Investment Adviser shall each be individually referred to as a “Party” and shall be collectively referred to as the “Parties”.</Typography>
                                    <Typography variant='subtitle1'>Whereas:</Typography>
                                    <Typography component="ul">
                                        <Typography component={'li'}>The Client seeks to appoint advisor(s) to provide certain investment advisory and other related services in relation to the curated stock portfolios of securities and has requested the Investment Adviser to render investment advisory services to it at his/her/ its risk.</Typography>
                                        <Typography component={'li'}>The Investment Adviser has agreed to be appointed as the Investment Adviser to the Client in accordance with the terms of this Agreement and SEBI (Investment Advisers) Regulations, 2013 as amended from time to time (“IA Regulations”) to provide with investment advisory services on a non-binding and non-exclusive basis and in a manner solely determined by itself.</Typography>
                                    </Typography>
                                    <Typography variant='button' sx={{fontWeight:600}}>1. REPRESENTATIONS AND WARRANTIES BY THE PARTIES</Typography>
                                    <br></br>
                                    <Typography variant='body'>The Parties hereto represent, warrant, and covenant to each other that:</Typography>
                                    {/* <Typography  */}
                                    <Typography component={'ul'}>
                                        <Typography component={'li'}>Each of the Parties are duly formed and validly existing under the respective laws that they are subject to with full power and authority to conduct the business as contemplated in this Agreement.</Typography>
                                        <Typography component={'li'}>Each Party has full power, capacity and authority to execute, deliver and perform this Agreement and has taken all necessary action (corporate, statutory or otherwise) to authorize the execution, delivery and performance of this Agreement.</Typography>
                                        <Typography component={'li'}>This Agreement and each other agreement executed in connection herewith, if any, have been duly executed and delivered by each Party and constitute legal, valid and binding obligations of such Party, enforceable against the other Party in accordance with the terms.</Typography>
                                        <Typography component={'li'}> Each Party has obtained and complied with all clearances, permissions, approvals, conditions and notices, that are or have been required, for the due execution and delivery of, and performance under this Agreement.</Typography>
                                        <Typography component={'li'}>Client Undertaking</Typography>
                                    </Typography>
                                    <Typography variant='body'>The Client understands and consents that it/he/she:</Typography>
                                    <Typography component={'ul'}>
                                        <Typography component={'li'}>have read and understood the terms and conditions of investment advisory services provided by the Investment Adviser and also understood the fee structure and mechanism for charging and payment of fees as under this Agreement.</Typography>
                                        <Typography component={'li'}>wants to avail the investment advisory services only for himself / herself and not for any other person.</Typography>
                                        <Typography component={'li'}>have, based on its written request to the Investment Adviser, been provided the opportunity by the Investment Adviser to ask questions and interact with ‘person(s) associated with the investment advice.</Typography>
                                        <Typography component={'li'}>has read the terms and conditions of Investment Advisory services provided by the Investment Adviser along with the fee structure and mechanism for charging and payment of fee. Further, the Investment Adviser based on the Client’s request in writing provided the Client an opportunity to ask questions and interact with person(s) associated with the investment advice.</Typography>
                                        <Typography component={'li'}>shall furnish any and all information as reasonably requested by the Investment Adviser for the purpose of risk profiling process. The risk profile as created by the Investment Adviser shall be final and binding on the Client and the Client consents to the Investment Adviser utilizing such information for the purpose of rendering investment advice services to the Client.</Typography>
                                    </Typography>
                                    <Typography variant='body'>Representations by the Investment Adviser</Typography>
                                    <Typography component={'ul'}>
                                        <Typography component={'li'}>The Investment Adviser shall ensure that it has appointed personnel of appropriate qualifications and experience to perform the services in order to fulfil its obligations under this Agreement.</Typography>
                                        <Typography component={'li'}>Investment Adviser shall neither render any investment advice nor charge any fee until the Client has signed this Agreement.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that it shall only recommend direct implementation of advice i.e. through direct schemes/direct codes where no consideration (including any embedded/indirect/in kind commission or referral fees by any name) is received directly or indirectly by the Investment Adviser or his /her family.</Typography>
                                        <Typography component={'li'}>Investment Adviser shall not manage funds and securities on behalf of the client and that it shall only receive such sums of monies from the client as are necessary to discharge the client’s liability towards fees owed to the Investment Adviser.</Typography>
                                        <Typography component={'li'}>Investment Adviser shall not, in the course of performing its services to the Client, hold out any investment advice implying any assured returns or minimum returns or target return or percentage accuracy or service provision till achievement of target returns or any other nomenclature that gives the impression to the Client that the investment advice is risk free and/or not susceptible to market risks and or that it can generate returns with any level of assurance.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that it is carrying on its activities on an arms-length relationship between its activities as an Investment Adviser and other activities and such arm’s length relationship shall be maintained while the existence of this Agreement.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrant that it is carrying on its activities independently, at an arms-length basis with its related parties. Disclosure of conflicts of interests, if any, shall be made by the Investment Adviser to the Client in a prompt manner.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that all appropriate registrations permissions and approvals which are statutorily required, have been validly maintained and shall continue to be in force as required for the performance of the Investment Adviser’s obligations under this Agreement.</Typography>
                                        <Typography component={'li'}>The Investment Adviser shall not derive any direct or indirect benefit out the Client’s securities and/or investment products.</Typography>
                                        <Typography component={'li'}>The Investment Adviser shall ensure that it will take all consents and permissions from the Client prior to undertaking any actions, including but not limited to implementation services in relation to the securities or investment products advised by the Investment Adviser, in a form and manner as under the IA Regulations.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that it shall not provide any distribution services to the Client.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that its family/group companies shall not provide distribution services to the Client advised by the Investment Adviser, for securities and investment products.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that its family/ group shall not provide investment advisory services to the Client who receives distribution services from the other family members of the Investment Adviser.</Typography>
                                        <Typography component={'li'}>The Investment Adviser represents and warrants that it shall not provide investment advisory services, for securities and investment products, to a Client who is receiving distribution services from its family members/ group.</Typography>
                                        <Typography component={'li'}>The investment adviser represents and warrants that it shall maintain client records and data as mandated under the securities and exchange board of India (Investment Adviser) Regulations 2013.</Typography>
                                    </Typography>
                                </div>
                            </div>
                        </Box>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubscribe}>Subscribe</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SubscribeFlow