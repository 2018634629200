import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";

const UserPortfolioMeta = (props) => {


    return (
        <>
            <Stack ml={3} direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 10 }} p={2} useFlexGap sx={{ flexWrap: 'wrap' }}>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Portfolios</Typography>
                    <Typography component={'span'} ml={8} variant="subtitle" justifyContent={'center'} id='portfolio_name'>{props.portfolioMetaData.total_ports}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Subscribed</Typography>
                    <Typography component={'span'} ml={10} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_subscribed}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total AUM</Typography>
                    <Typography component={'span'} ml={3} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_aum}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Return</Typography>
                    <Typography component={'span'} ml={3} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_return}</Typography>
                </Stack>
            </Stack>
        </>
    )
}

export default UserPortfolioMeta