import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid2 } from "@mui/material";
import Header from "../../components/common/header";
import PortfolioEditMetaInputs from "./portfolioEditMetaData";
import { useNavigate, useParams } from "react-router-dom";
import SymbolEvalData from "../../components/portfolio/symbolEvalData";

const EditPortfolio = () => {

    const {id, type} = useParams()
    const navigate = useNavigate();
    const [metaData, setMetaData] = useState({});
    const [symbol, setSymbol] = useState('');
    const [showGraph, setShowGraph] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMetaData({
          ...metaData,
          [name]: value,
        });
      };

    const updateMetaData = (newData) => {
        setMetaData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    useEffect(()=>{
        console.log('Running get portfolio')
        getPortfolioData().then(() => {
            setShouldRender(true);
        })
    },[])
    const getPortfolioData = async() => {
        // const portfolio_id = 1;
        const formData ={
            portfolio_id : id,
            type : type
        }
        try{
            const res = await axios.get('http://localhost:5000/portfolio/get_portfolio_meta_data', {params: formData})
            const data = res.data;
            console.log(data, 'meta data bro')
            setMetaData(data)
        }catch(err){
            console.log(err, 'error');
            
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
            // navigate(-1);
        }
    }

    const handleSubmit = async(event) => {
        // const formData = {
        //     'portfolio_name' : document.getElementById('portfolio_name').value,
        //     'risk_profile' : riskProfile,
        //     'min_investment' : minInvestment,
        //     'rebalance' : rebalance,
        //     'portfolio_coverage' : portfolioCoverageData,
        //     'type' : event.target.value,
        //     'weighted_beta' : weightedBeta
        // }
        const formObject = metaData
        
        const symbolElementsList = document.getElementsByName('symbol_name')
        const qtyElementsList = document.getElementsByName('symbol_quantity')
        const priceElementsList = document.getElementsByName('symbol_price')
        const coverageElementsList = document.getElementsByName('symbol_coverage')

        const stockData = []
        for (var i=0; i<symbolElementsList.length; i++){
            const tempData = {
                symbol : symbolElementsList[i].value,
                quantity : qtyElementsList[i].value,
                price : priceElementsList[i].value,
                coverage : coverageElementsList[i].value,
                asset_type : 'equity'
            }
            stockData.push(tempData)
        }
        formObject['symbol_data'] = stockData
        formObject['type'] = event.target.value
        delete formObject['selected_stocks']
        console.log(formObject, 'form object dude')
        if (!formObject.portfolio_name){
            toast.error('Please Add Portfolio Name')
            return
        }
        try {
            const res = await axios.post('http://localhost:5000/portfolio/create_curated_portfolio', formObject)
            toast.success('Portfolio Created Successfully')
            navigate('/dashboard/'+type+'/'+id)
        } catch(err){
            if (!err.response){
                console.log(err, 'error')
                toast.error(err.message, 'error message')
            } else {
                console.log(err, 'error bro')
                toast.error(err.response.data.message);
                console.log(err.response.data.message, 'message from api')
            }
        }
        // console.log(formObject);
        console.log('Submit Processed');
    }
    const handleShowStock = (e) => {
        setSymbol(e.value)
        setShowGraph(true);
    }

    return (
        <>
            <Header userType={type} userId={id} stocksList={true} handleChange={handleShowStock}></Header>
            { shouldRender ? 
            <Grid2 sx={{ flexGrow: 1 }} container spacing={1} mt={1} ml={1}>
                <Grid2 xs={12}>
                    <Grid2 container spacing={2} sx={{ justifyContent: 'left' }}>
                        <Grid2 key={0}>
                            <PortfolioEditMetaInputs metaData={metaData} updateMetaData={updateMetaData} onInputChange={handleInputChange} onSubmit={handleSubmit} />
                        </Grid2>
                        <Grid2 key={1}>
                            {showGraph ?
                                <SymbolEvalData symbol={symbol} formData={metaData} handleInputChange={updateMetaData}/>
                                : ''
                            }
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
            : ''
                        }
            {/* {shouldRender ? 
            <PortfolioEditMetaInputs metaData={metaData} updateMetaData={updateMetaData}></PortfolioEditMetaInputs>
            : ''
            } */}
        </>
    )
}

export default EditPortfolio