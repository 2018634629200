import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import Header from "../../components/common/header";
import FundDashPortfolioMeta from "../../components/dashboard/fundDashPortfolioMeta";
import UserPortfolioMeta from "../../components/dashboard/userDashMeta";
import PortfolioCard from "../../components/dashboard/portfolioCardComponent";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const Dashboard = () => {

    const {type, userId} = useParams();

    const navigate = useNavigate()
    const [metaData, setMetaData] = useState({});
    const [portfoliosData, setPortfoliosData] = useState([]);
    const [renderChild, setRenderChild] = useState(false);
    const [reloadStatus, setReloadStatus] = useState(false);

    var pdata = []
    useEffect(()=>{
        getPortfoliosList();
    }, [reloadStatus])

    const handleCreatePortfolio = () => {
        navigate('/new_portfolio/'+type+'/'+userId)
    }
    const getPortfoliosList = async() => {
        const formData = {
            type : type,
            user_id: userId
        }
        try{
            const res = await axios.get('http://localhost:5000/portfolio/get_all_portfolios', {params: formData})
            const resp_data = res.data.portfolios
            const meta_data = res.data.meta
            setMetaData(meta_data);
            setPortfoliosData(resp_data);
            setRenderChild(true);
            console.log(resp_data, 'resp data');
            console.log(renderChild, 'render child')
            pdata = resp_data
        } catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else if (err.response.status == 504){
                setMetaData({'total_ports' : 0, 'total_saved' : 0, 'total_published' : 0, 'total_subscribed':0, 'total_aum' : 0})
                setRenderChild(true);
                toast.error('No Portfolios Found, Please Subscribe to see Portfolios here')
            
            } else {
                toast.error(err.response.data.message);
            }
        }
    }
    return (
        <>
        <Header stocksList={false} userType={type} userId={userId}></Header>
        { renderChild && 
        <Box width={'100%'} mt={2}>
            <div>
                <Box width={'100%'} display={'flex'} justifyContent={'right'} ml={-5} mb={1}>
                    <Button variant='contained' size='small' onClick={handleCreatePortfolio}>Create Portfolio</Button>  
                </Box>
            <Box width={'95%'} border={'1px solid black'} borderRadius={2} ml={4}>
            { type === 'fund' ? 
            <div>
                <FundDashPortfolioMeta userId={userId} portfolioMetaData={metaData}></FundDashPortfolioMeta>
            </div>
                : 
                <UserPortfolioMeta portfolioMetaData={metaData}></UserPortfolioMeta>
            }
            </Box>
            {/* <Box height={400} width={'95%'} border={'1px solid black'} borderRadius={2} ml={4} mt={2}> */}
            <Box width={'95%'} borderRadius={2} ml={4} mt={2}>
                <Box width={'100%'} display={'grid'} gridTemplateColumns={'repeat(4, 1fr)'} p={1}>
                    {portfoliosData.map((elem, index) => {
                        // <div>
                        return (
                            <div key={index}>
                                <PortfolioCard userType={type} reload={reloadStatus} setReload={setReloadStatus} index={index} portfolioData={elem}></PortfolioCard> 
                            </div>
                        )

                        // </div>
                    })}
                </Box>
                    {/* {portfoliosData.forEach((portfolio, index)=>{ */}
                        {/* <div>
                            <PortfolioCard index={'1'} portfolioData={portfolio_data}></PortfolioCard>

                        </div> */}
                    {/* })} */}
            </Box>
            </div>
        </Box>
            }
        </>
    )
}

export default Dashboard