import React, { useEffect } from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FundDashPortfolioMeta = (props) => {

    const navigate = useNavigate()
    const handleCreatePortfolio = () => {
        navigate('/new_portfolio/fund/'+props.userId)
    }
    useEffect(()=>{
        console.log(props.portfolioMetaData, 'port meta dtaa')
    })

    return (
        <>
            {/* <Box width={'100%'} display={'flex'} justifyContent={'right'} mt={1}>
                <Button variant='contained' size="small" onClick={handleCreatePortfolio}>Create Portfolio</Button>
            </Box> */}
            <Stack ml={3} direction={{ xs: 'row', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 10 }} p={2} useFlexGap sx={{ flexWrap: 'wrap' }}>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Portfolios</Typography>
                    <Typography component={'span'} ml={8} variant="subtitle" justifyContent={'center'} id='portfolio_name'>{props.portfolioMetaData.total_ports}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Saved</Typography>
                    <Typography component={'span'} ml={6} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_saved}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Published</Typography>
                    <Typography component={'span'} ml={8} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_published}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total Subscribers</Typography>
                    <Typography component={'span'} ml={10} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_subscribed}</Typography>
                </Stack>
                <Stack direction={'column'}>
                    <Typography component={'span'} variant="h6" sx={{fontWeight:600}}>Total AUM</Typography>
                    <Typography component={'span'} ml={3} variant="subtitle" justifyContent={'center'}>{props.portfolioMetaData.total_aum}</Typography>
                </Stack>
                {/* <Stack direction={'column'}>
                    <Button variant='contained' size="small" onClick={handleCreatePortfolio}>Create Portfolio</Button>
                </Stack> */}
            </Stack>
        </>
    )
}

export default FundDashPortfolioMeta