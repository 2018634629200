import React, { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import './index.css'
import myLogo from './Tyck-logo-feb-13.svg'
import { toast } from 'react-toastify';
import axios from "axios";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const steps = [
    'Email Verification',
    'Terms and Conditions',
    'User Verification',
    'Risk Profiling', 
    'Curated Portfolios'
];

function UserVerify(props){

    
    const [isAadharOTPSent, setIsAadharOTPSent] = useState(false);
    const [isPANOTPSent, setIsPANOTPSent] = useState(false);
    const [isAadharVerified, setIsAadharVerified] = useState(false);
    const [isPanVerified, setIsPanVerified] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [aadhar, setAadhar] = useState('');
    const [pan, setPan] = useState('');
    const isValidAadhar = "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$"
    const isValidPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/

    const onAadharSubmit = async () => {
        const formData = {
            aadhar_number : document.getElementById("aadhar").value,
        }
        if (formData.aadhar_number.length === 0 ) {
            toast.error('Aadhar Field is Empty')
            return;
        }
        if (!formData.aadhar_number.match(isValidAadhar)){
            toast.error('Aadhar Number Not Valid!!')
            return;
        }
        try {
            // const res = await axios.post("http://localhost:5000/userRegister/verify_aadhar?aadhar_number="+formData.aadhar_number);
            await axios.post("http://localhost:5000/userRegister/verify_aadhar",formData);
            toast.success('OTP Sent Successfully')
            setIsAadharOTPSent(true);
            setAadhar(formData.aadhar_number);
        } catch(err){
            console.log(err)
            toast.error(err.response.data.message)
        }
    }
    const onAadharOTPSubmit = async () => {
        // const onOtpSubmit = async () => {

            const formData = {
                otp: document.getElementById("aadhar_otp").value,
                aadhar_number: aadhar
            }
            if (!formData.otp.length){
                toast.error('OTP Field is Empty')
                return
            }
            if (!formData.otp.length === 6){
                toast.error('Not a Valid OTP')
                return
            }
            
            try {
                await axios.post("http://localhost:5000/userRegister/verify_aadhar_otp", formData);
                // handleChange('panel2')
                setExpanded('panel2')
                setIsAadharVerified(true);
                // sendDataFromAadharVerify(true)
                toast.success('Aadhar Validated Successfully');
            } catch(err){
                toast.error(err.response.data.message);
            }
        // }
    }
    const onPANSubmit = async () => {
        const formData = {
            pan_number : document.getElementById("pan_number").value,
        }
        if (formData.pan_number.length === 0 ) {
            toast.error('Pan Number Field is Empty')
            return;
        }
        console.log(formData.pan_number)
        if (!formData.pan_number.match(isValidPan)){
            toast.error('Pan Number Not Valid!!')
            return;
        }
        try {
            // const res = await axios.post("http://localhost:5000/userRegister/verify_aadhar?aadhar_number="+formData.aadhar_number);
            await axios.post("http://localhost:5000/userRegister/verify_pan",formData);
            toast.success('OTP Sent Successfully')
            setIsPANOTPSent(true);
            setPan(formData.pan_number);
        } catch(err){
            console.log(err)
            toast.error(err.response.data.message)
        }
    }
    const onPANOTPSubmit = async () => {
        const formData = {
            otp: document.getElementById("pan_otp").value,
            pan_number: pan
        }
        if (!formData.otp.length){
            toast.error('OTP Field is Empty')
            return
        }
        if (!formData.otp.length === 6){
            toast.error('Not a Valid OTP')
            return
        }
        
        try {
            await axios.post("http://localhost:5000/userRegister/verify_pan_otp", formData);
            setIsPanVerified(true);
            // sendDataFromPanVerify(true)
            toast.success('PAN Validated Successfully');
            props.nextStep();
        } catch(err){
            toast.error(err.response.data.message);
        }
    }
    const handleChange =
        (panel) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>
            <Box sx={{ width: '80%'}} mt={10} ml={15}>
                <Stepper activeStep={2} alternativeLabel>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </Box>

            <div className='data-box'>
                <Box width={'30%'} mt={10} className='email-verify-box'>
                    <div class="logo-container">
                        <img src={myLogo} className="App-logo" alt="logo" />
                    </div>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disabled={isAadharVerified?true:false}>
                        <AccordionSummary expandIcon={isAadharVerified?<CheckCircleIcon sx={{color:'green'}}></CheckCircleIcon>:<ExpandMoreIcon />} aria-controls="aadhar-content" id="aadhar-header">
                            Aadhar Verification
                        </AccordionSummary>
                        <AccordionDetails>
                            {isAadharOTPSent?"" : 
                                <div className="emailverifyblock">
                                    <TextField required id="aadhar" label="Aadhar Number" size="small" />
                                    <br/><br/>
                                    <Button variant="contained" size="small" onClick={onAadharSubmit}>Get OTP</Button>
                                </div>
                            }
                            {isAadharOTPSent ?
                                <div className="emailotpverifyblock">
                                    <TextField required id="aadhar_otp" label="Aadhar OTP" size="small" />
                                    <br/><br/>
                                    <Button variant="contained" size="small" onClick={onAadharOTPSubmit}>Submit OTP</Button>
                                </div> : ""
                            }
                        </AccordionDetails>
                    </Accordion>
                    <br></br>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary expandIcon={isPanVerified?<CheckCircleIcon sx={{color:'green'}}></CheckCircleIcon>:<ExpandMoreIcon />} aria-controls="pan-content" id="pan-header">
                            PAN Verification
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* <AadharVerify sendDataFromAadharVerify={handleDataFromAadharVerify} /> */}
                            {isPANOTPSent?"" : 
                                <div className="emailverifyblock">
                                    <TextField required id="pan_number" label="PAN Number" size="small" />
                                    <br/><br/>
                                    <Button variant="contained" size="small" onClick={onPANSubmit}>Get OTP</Button>
                                </div>
                            }
                            {isPANOTPSent ?
                                <div className="emailotpverifyblock">
                                    <TextField required id="pan_otp" label="PAN OTP" size="small" />
                                    <br/><br/>
                                    <Button variant="contained" size="small" onClick={onPANOTPSubmit}>Submit OTP</Button>
                                </div> : ""
                            }
                        </AccordionDetails>
                    </Accordion>
                    {/* {isAadharVerified?"" : 
                        <div className="emailverifyblock">
                            <TextField required id="aadhar" label="Aadhar Number" size="small" />
                            <br/><br/>
                            <Button variant="contained" size="small" onClick={onFormSubmit}>Get OTP</Button>
                        </div>
                    } */}
                </Box>
            </div>

        </>
    )
}

export default UserVerify