import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import axios from "axios";
import Header from "../../components/common/header";
import PortfolioMetaData from "../../components/portfolio/portfolioMetaData";
import PortfolioEvaluatorsData from "../../components/portfolio/portfolioEvaluatorsData";
import { FundPortfolioEvaluatorsData } from "../../components/portfolio/portfolioEvaluatorsData";
import PortfolioGraphData from "../../components/portfolio/portfolioGraph";
import PortfolioStockTable from "../../components/portfolio/portfolioStockTable";
import { KiteWebSocketConnect } from "../../components/common/liveData";
import { WebSocketProvider } from "../../components/common/webSocketContext";// import { WebSocketContext } from "../../components/common/webSocketContext";

const PortfolioDetails = () => {
    
    const {id, type } = useParams();

    // const socket = useContext(WebSocketContext)
    const [shouldRender, setShouldRender] = useState(false);
    const [pnlGraphData, setPnlGraphData] = useState([]);
    const [backtestGraphData, setBacktestGraphData] = useState([]);
    const [stocksTableData, setStocksTableData] = useState([]);
    const [ws, setWs] = useState();
    const [metaData, setMetaData] = useState({})
    const [MTOM, setMTOM] = useState(0);
    const [totalPnl, setTotalPnl] = useState(0);

    const updateTablecell = ((rowId, field, value) => {
        setStocksTableData((prevData) =>
            prevData.map((row) => 
                row.pair_id === rowId ? { ...row, exit: value } : row
            )
        );
        console.log(stocksTableData, 'stock table data after updation');
        
    })
    useEffect(() => {
        console.log('stocks data updated')
    },[stocksTableData])


    useEffect(()=>{
        getPortfolioData().then(() => {
            setShouldRender(true);
        })
    },[])
    const getPortfolioData = async() => {
        // const portfolio_id = 1;
        const formData ={
            portfolio_id : id,
            type : type
        }
        try{
            const res = await axios.get('http://localhost:5000/portfolio/get_curated_portfolio', {params: formData})
            const data = res.data;
            const meta_data = data.meta_data;
            const pnl_data = data.portfolio_pnl_data;
            const pnl_graph_data = pnl_data.live_pnl_data;
            const backtest_graph_data = pnl_data.backtest_pnl_data;
            const stocks_table_data = pnl_data.stocks_table_data;
            console.log(stocks_table_data, 'stock table data')
            setPnlGraphData(pnl_graph_data);
            setBacktestGraphData(backtest_graph_data);
            setStocksTableData(stocks_table_data);

            stocks_table_data.map((row) => {
                console.log(row.pair_id, 'pair id')
                if(ws){
                    console.log('Subscribing to '+row.pair_id)
                    ws.send(JSON.stringify({a: 'subscribe', v: [row.pair_id]}))
                }
            })
            console.log(stocks_table_data, 'stocks tabel data')
            setMetaData(meta_data);
            if(type === 'user' && id === 2){
                toast.error('Portfolio Has been Rebalanced By Fund Manager, Please Rebalance your Portfolio')
            }
           
        }catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
            // navigate(-1);
        }
    }


    return(
        <>
            <Header userId={id} userType={type} stocksList={false} ></Header>
            <PortfolioMetaData portfolio_id={id} metaData={metaData} MTOM={MTOM}></PortfolioMetaData>
            <Box width={'96%'} mt={2} ml={3} height={400} display={'flex'} gap={2}>
                { type === 'fund' ?
                    <FundPortfolioEvaluatorsData metaData={metaData}></FundPortfolioEvaluatorsData>
                    :
                    <PortfolioEvaluatorsData metaData={metaData}></PortfolioEvaluatorsData>
                }
                { shouldRender ?
                    <PortfolioGraphData pnlGraphData={pnlGraphData} backtestGraphdata={backtestGraphData}></PortfolioGraphData>
                    :
                    ''
                }
            </Box>
            <Box width={'96%'} mt={2} ml={3} sx={{boxShadow: '0px 0px 10px #888888', height: 300, borderRadius: 2}}>
                <WebSocketProvider>
                    <PortfolioStockTable updateTablecell={updateTablecell} setStocksTableData={setStocksTableData} stocksTableData={stocksTableData}></PortfolioStockTable>
                </WebSocketProvider>
            </Box>
        </>
    )
}

export default PortfolioDetails