import React, { useState } from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import { Gauge } from '@mui/x-charts/Gauge';
import Typography from '@mui/material/Typography';

const steps = [
    'Email Verification',
    'Terms and Conditions',
    'User Verification',
    'Risk Profiling',
    'Curated Portfolios'
];
let rpScore = 0

function RiskProfile(props) {
    const [currentQuestion, setCurrentQuestion] = useState(0)
    // const [score, setScore] = useState('0')
    const [selected, setSelected] = useState()
    const [questionsEnd, setQuestionsEnd] = useState(false);
    // const [riskProfile, setRiskProfile] = useState('')
    

    const handleSubmit = (event) => {
        if (!selected){
            toast.error('Please choose one Option!!!')
            return
        }
        rpScore = parseInt(rpScore) + parseInt(selected)
        
        if (currentQuestion +1 < 6){
            setCurrentQuestion(currentQuestion+1)
            setSelected('')
        } else {
            console.log(rpScore);
            if (rpScore >= 60 && rpScore <= 96){
                props.setRiskProfile('Very Conservative')
            } else if (rpScore >= 97 && rpScore <= 132){
                props.setRiskProfile('Conservative')
            } else if (rpScore >= 133 && rpScore <= 168){
                props.setRiskProfile('Moderate')
            } else if (rpScore >= 169 && rpScore <= 204){
                props.setRiskProfile('Agressive')
            } else if (rpScore >= 205 && rpScore <= 240){
                props.setRiskProfile('Very Agressive')
            }
            setQuestionsEnd(true)
        }
    }
    const handleRadioChange = (event) => {
        setSelected(event.target.value);
      };
    const handleFinal = () => {
        props.nextStep();
    }
    return (
        <>
            <Box sx={{ width: '80%'}} mt={10} ml={15}>
                <Stepper activeStep={3} alternativeLabel>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </Box>
            <div className='data-box'>
            <Box width={'50%'} mt={10} className='rp-verify-box'>
                {questionsEnd?
                        <div>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }}>
                        <Gauge width={100} height={100} value={rpScore} />
                    </Stack>
                    <div className='rp-text'>
                        <Typography variant='h5' sx={{margin: '0 auto'}}> Your Risk Profile is : {props.riskProfile}</Typography>
                    </div>
                    <div className='rp-final'>
                        <Button variant='contained' size='small' onClick={handleFinal}>continue</Button>
                    </div>
                    </div>
                :
                <form>
                {currentQuestion === 0?
                <FormControl>
                    <FormLabel id="question1-label">1. In which investments do you have highest allocation?</FormLabel>
                    <RadioGroup aria-labelledby="question1-label" name="question1" id="question1" onChange={handleRadioChange}>
                        <FormControlLabel value="10" control={<Radio size="small" />} label="Liquid funds and FDs" />
                        <FormControlLabel value="20" control={<Radio size="small"/>} label="Debt funds"  />
                        <FormControlLabel value="30" control={<Radio size="small"/>} label="Equity funds" />
                        <FormControlLabel value="40" control={<Radio size="small"/>} label="Alternate assets (PMS, AIFs)" />
                    </RadioGroup>
                </FormControl> : "" }
                {currentQuestion === 1?
                <FormControl>
                    <FormLabel id="question2-label">2. What factor is most important for you while choosing an investment?</FormLabel>
                    <RadioGroup aria-labelledby="question2-label" name="question2" id="question2" onChange={handleRadioChange}>
                        <FormControlLabel value="10" control={<Radio size="small" />} label="Highest potential return" />
                        <FormControlLabel value="20" control={<Radio size="small"/>} label="Regular income & marginal capital appreciation"  />
                        <FormControlLabel value="30" control={<Radio size="small"/>} label="Regular income" />
                        <FormControlLabel value="40" control={<Radio size="small"/>} label="Preservation of capital" />
                    </RadioGroup>
                </FormControl> : "" }
                {currentQuestion === 2?
                <FormControl>
                    <FormLabel id="question3-label">3. You have inherited a sizeable amount recently. How would you invest the same?</FormLabel>
                    <RadioGroup aria-labelledby="question3-label" name="question3" id="question3" onChange={handleRadioChange}>
                        <FormControlLabel value="10" control={<Radio size="small" />} label="Invest entirely into equities" />
                        <FormControlLabel value="20" control={<Radio size="small"/>} label="Invest 50% into equities and rest into xed income"  />
                        <FormControlLabel value="30" control={<Radio size="small"/>} label="Invest entirely into fixed income" />
                        <FormControlLabel value="40" control={<Radio size="small"/>} label="Deposit it in bank or invest in liquid funds" />
                    </RadioGroup>
                </FormControl> : "" }
                {currentQuestion === 3?
                <FormControl>
                    <FormLabel id="question4-label">4. Suppose you own an equity portfolio that has fallen by 20% this month. How would you react?</FormLabel>
                    <RadioGroup aria-labelledby="question4-label" name="question4" id="question4" onChange={handleRadioChange}>
                        <FormControlLabel value="10" control={<Radio size="small" />} label="I would invest more in this portfolio" />
                        <FormControlLabel value="20" control={<Radio size="small"/>} label="I would not change this portfolio at all"  />
                        <FormControlLabel value="30" control={<Radio size="small"/>} label="I would wait for few months and then decide whether to move to a more conservative portfolio" />
                        <FormControlLabel value="40" control={<Radio size="small"/>} label="I would immediately change to a more conservative portfolio" />
                    </RadioGroup>
                </FormControl> : "" }
                {currentQuestion === 4?
                <FormControl>
                    <FormLabel id="question5-label">5. Approximately what portion of your monthly net income goes towards paying of installments
                    (for loans)?</FormLabel>
                    <RadioGroup aria-labelledby="question5-label" name="question5" id="question5" onChange={handleRadioChange}>
                        <FormControlLabel value="10" control={<Radio size="small" />} label="Less than 10%" />
                        <FormControlLabel value="20" control={<Radio size="small"/>} label="Between 11% and 25%"  />
                        <FormControlLabel value="30" control={<Radio size="small"/>} label="Between 26% and 50%" />
                        <FormControlLabel value="40" control={<Radio size="small"/>} label="More than 51%" />
                    </RadioGroup>
                </FormControl> : "" }
                {currentQuestion === 5?
                <FormControl>
                    <FormLabel id="question6-label">6. Which of the following age brackets do you currently fall in?</FormLabel>
                    <RadioGroup aria-labelledby="question6-label" name="question6" id="question6" onChange={handleRadioChange}>
                        <FormControlLabel value="10" control={<Radio size="small" />} label="Less than 30 years of age" />
                        <FormControlLabel value="20" control={<Radio size="small"/>} label="Between 30 and 45 years of age"  />
                        <FormControlLabel value="30" control={<Radio size="small"/>} label="Between 45 and 60 years of age" />
                        <FormControlLabel value="40" control={<Radio size="small"/>} label="Above 60 years of age" />
                    </RadioGroup>
                </FormControl> : "" }
                <div className='rp-button-options'>
                    <Button variant="contained" size="medium" onClick={handleSubmit}>Submit</Button>
                    {/* <Button variant="contained" size="small" onClick={onOtpSubmit}>Submit OTP</Button> */}
                </div>
                </form>
            }
            </Box>
            </div>
        </>
    )
}

export default RiskProfile