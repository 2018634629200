import { Box, Tabs, Tab, Typography } from "@mui/material"
import PropTypes from 'prop-types';
// import Chart from "react-apexcharts";
import DrawCharts from "./drawCharts";
import { useEffect, useState , memo} from "react";

const PortfolioGraphData = memo((props) => {

    // const [graphType, setGraphType] = useState('pnl');
    const [graphTabsValue, setGraphTabsValue] = useState(0);
    const [liveChartType, setLiveChartType] = useState('line');
    const [backtestChartType, setBacktestChartType] = useState('line');
    const [pnlSeriesData, setPnlSeriesData] = useState([])
    const [pnlLabelData, setPnlLabelData] = useState([])
    const [backtestSeriesData, setBacktestSeriesData] = useState([])
    const [backtestLabelData, setBacktestLabelData] = useState([]);
    const [parameter, setParamter] = useState('pnl');
    const [backtestParameter, setBacktestParameter] = useState('pnl');
    const [backtestDuration, setBacktestDuration] = useState('yearly');
    const [liveDropDownVisible, setLiveDropDownVisible] = useState(false);
    const [backtestDropDownVisible, setBacktestDropDownVisible] = useState(false);
    const [graphDuration, setGraphDuration] = useState('monthly');

    // useEffect(() => {
    //     massageData(props, parameter, graphDuration);
    // },[graphTabsValue, props, parameter])
    // console.log(props.pnlGraphData, 'pnllll');
    useEffect(() => {
        massageData(props, parameter, graphDuration);
    },[])
    const massageData = () => {
        const pnl_data = props.pnlGraphData
        const backtest_data = props.backtestGraphdata
        console.log(pnl_data, 'pnl data')
        console.log(backtest_data, 'backtest data')
        console.log(Object.keys(pnl_data).length)
        if (Object.keys(pnl_data).length === 0 || Object.keys(backtest_data).length === 0){
            console.log('Empty Data')
        } else {
        const pnl_graph_data = pnl_data
        const backtest_graph_data = backtest_data
        const series_data = [];
        const backtest_series_data = [];
        var label_data = []
        var backtest_label_data = []
        console.log(pnl_graph_data)
        console.log(backtest_graph_data, 'baktest graph data')
        if (!Object.keys(pnl_graph_data).length === 0){
            Object.entries(pnl_graph_data).forEach(([key,value]) => {
                const temp_data = {data: pnl_graph_data[key]['data'], name: key}
                series_data.push(temp_data)
                label_data = pnl_graph_data[key]['label']
            })
        }
        Object.entries(backtest_graph_data).forEach(([key,value]) => {
            console.log(key, 'key');
            console.log(backtestParameter, 'parameter');
            console.log(backtestDuration, 'baktest duration');
            
            const temp_data = {data: backtest_graph_data[key][backtestParameter][backtestDuration]['data'], name: key}
            backtest_series_data.push(temp_data)
            backtest_label_data = backtest_graph_data[key][backtestParameter][backtestDuration]['labels']
        })
        setPnlLabelData(label_data);
        setPnlSeriesData(series_data);
        setBacktestLabelData(backtest_label_data);
        setBacktestSeriesData(backtest_series_data);
    }
    }

    const handleGraphTabChange = (event, newValue) => {
        console.log(newValue, 'new value');
        setGraphTabsValue(newValue);
    };

    const handleLiveDropdownItemClick = (event) => {
        setLiveDropDownVisible(!liveDropDownVisible);
    }

    const handleBacktestDropDownItemClick =(event) => {
        setBacktestDropDownVisible(!backtestDropDownVisible);
    }

    const handleBacktestMenuClick = () => {
        setLiveDropDownVisible(!liveDropDownVisible);
    }

    function updateBacktestGraphData(duration){
        const backtest_series_data = []
        const backtest_label_data = ''
        Object.entries(props.backtestGraphData).forEach(([key,value]) => {
            const temp_data = {data: props.backtestGraphData[key][backtestParameter][duration]['data'], name: key}
            backtest_series_data.push(temp_data)
            backtest_label_data = props.backtestGraphData[key][backtestParameter][duration]['labels']
        })
        setBacktestSeriesData(backtest_series_data);
        setBacktestLabelData(backtest_label_data);
        setBacktestDuration(duration)
    }

    // const handleGraphTabChange = (event, newValue) => {
    //     setGraphTabsValue(newValue);
    // };
    // Graph Panel Code
    function GraphTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      GraphTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };

    return(
        <Box width={'65%'} p={2} height={350} sx={{boxShadow: '0px 0px 10px #888888'}} borderRadius={2}>
            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Tabs value={graphTabsValue} onChange={handleGraphTabChange} aria-label="disabled tabs">
                    <Tab label="PNL" />
                    <Tab label="Backtest" />
                </Tabs>
            </Box>
            <Box width={'100%'}>
                <GraphTabPanel value={graphTabsValue} index={0}>
                    {pnlSeriesData.length > 0 ? 
                        <DrawCharts graphType={'pnl'} seriesData={pnlSeriesData} labelData={pnlLabelData} chartType={liveChartType} handleDropdownItemClick={handleLiveDropdownItemClick} setChartType={setLiveChartType} DropDownVisible={liveDropDownVisible}></DrawCharts>
                        :
                        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                            <Typography variant="subtitle">Pnl Graphs can Only be generated after T+2 Days</Typography>
                        </Box>
                    }
                </GraphTabPanel>
                <GraphTabPanel value={graphTabsValue} index={1}>
                    <DrawCharts graphType={'backtest'} setBacktestParameter={setBacktestParameter} setBacktestDuration={setBacktestDuration} seriesData={backtestSeriesData} labelData={backtestLabelData} chartType={backtestChartType} handleDropdownItemClick={handleBacktestDropDownItemClick} setChartType={setBacktestChartType} DropDownVisible={backtestDropDownVisible} updateGraphData={updateBacktestGraphData} handleMenuClick={handleBacktestMenuClick}></DrawCharts>
                </GraphTabPanel>
            </Box>
        </Box>
    )
})

export default PortfolioGraphData