import React, { createContext, useEffect, useState, useCallback } from 'react';


function buf2long(buf) {
    let b = new Uint8Array(buf), val = 0, len = b.length;
    for (let i = 0, j = len - 1; i < len; i++, j--) {
        val += b[j] << (i * 8);
    }
    return val;
}

function splitPackets(bin) {
    // number of packets
    let num = buf2long(bin.slice(0, 2)), j = 2, packets = [];
    for (let i = 0; i < num; i++) {
        // first two bytes is the packet length
        const size = buf2long(bin.slice(j, j + 2)), packet = bin.slice(j + 2, j + 2 + size);
        packets.push(packet);
        j += 2 + size;
    }
    return packets;
}

export function parseLiveBinary(binpacks) {
    const NseCM = 1, NseFO = 2, NseCD = 3, BseCM = 4, BseFO = 5, BseCD = 6, McxFO = 7, McxSX = 8, Indices = 9;
    const modeFull = 'full', modeQuote = 'quote', modeLTP = 'ltp';
    const packets = splitPackets(binpacks), ticks = [];
    for (let n = 0; n < packets.length; n++) {
        const bin = packets[n], instrument_token = buf2long(bin.slice(0, 4)), segment = instrument_token & 0xff;
        let tradable = true;
        if (segment === Indices)
            tradable = false;
        // Add price divisor based on segment
        let divisor = 100.0;
        if (segment === NseCD) {
            divisor = 10000000.0;
        }
        else if (segment === BseCD) {
            divisor = 10000.0;
        }
        // Parse LTP
        if (bin.byteLength === 8) {
            ticks.push({
                tradable: tradable,
                mode: modeLTP,
                instrument_token,
                last_price: buf2long(bin.slice(4, 8)) / divisor
            });
            // Parse indices quote and full mode
        }
        else if (bin.byteLength === 28 || bin.byteLength === 32) {
            let mode = modeQuote;
            if (bin.byteLength === 32)
                mode = modeFull;
            const tick = {
                tradable,
                mode,
                instrument_token,
                last_price: buf2long(bin.slice(4, 8)) / divisor,
                ohlc: {
                    high: buf2long(bin.slice(8, 12)) / divisor,
                    low: buf2long(bin.slice(12, 16)) / divisor,
                    open: buf2long(bin.slice(16, 20)) / divisor,
                    close: buf2long(bin.slice(20, 24)) / divisor
                },
                change: buf2long(bin.slice(24, 28))

            };
            // Compute the change price using close price and last price
            if (tick.ohlc.close !== 0) {
                tick.change = (tick.last_price - tick.ohlc.close) * 100 / tick.ohlc.close;
            }
            // Full mode with timestamp in seconds
            if (bin.byteLength === 32) {
                tick.exchange_timestamp = null;
                const timestamp = buf2long(bin.slice(28, 32));
                if (timestamp)
                    tick.exchange_timestamp = new Date(timestamp * 1000);
            }
            ticks.push(tick);
        }
        else if (bin.byteLength === 44 || bin.byteLength === 184) {
            let mode = modeQuote;
            if (bin.byteLength === 184)
                mode = modeFull;
            const tick = {
                tradable,
                mode,
                instrument_token,
                last_price: buf2long(bin.slice(4, 8)) / divisor,
                last_traded_quantity: buf2long(bin.slice(8, 12)),
                average_traded_price: buf2long(bin.slice(12, 16)) / divisor,
                volume_traded: buf2long(bin.slice(16, 20)),
                total_buy_quantity: buf2long(bin.slice(20, 24)),
                total_sell_quantity: buf2long(bin.slice(24, 28)),
                ohlc: {
                    open: buf2long(bin.slice(28, 32)) / divisor,
                    high: buf2long(bin.slice(32, 36)) / divisor,
                    low: buf2long(bin.slice(36, 40)) / divisor,
                    close: buf2long(bin.slice(40, 44)) / divisor
                },
                // To be computed later
                change: 0,
            };
            // Compute the change price using close price and last price
            if (tick.ohlc.close !== 0) {
                tick.change = (tick.last_price - tick.ohlc.close) * 100 / tick.ohlc.close;
            }
            // Parse full mode
            if (bin.byteLength === 184) {
                // Parse last trade time
                tick.last_trade_time = null;
                const last_trade_time = buf2long(bin.slice(44, 48));
                if (last_trade_time)
                    tick.last_trade_time = new Date(last_trade_time * 1000);
                // Parse timestamp
                tick.exchange_timestamp = null;
                const timestamp = buf2long(bin.slice(60, 64));
                if (timestamp)
                    tick.exchange_timestamp = new Date(timestamp * 1000);
                // Parse OI
                tick.oi = buf2long(bin.slice(48, 52));
                tick.oi_day_high = buf2long(bin.slice(52, 56));
                tick.oi_day_low = buf2long(bin.slice(56, 60));
                tick.depth = {
                    buy: [],
                    sell: []
                };
                let s = 0, depth = bin.slice(64, 184);
                for (let i = 0; i < 10; i++) {
                    s = i * 12;
                    tick.depth[i < 5 ? 'buy' : 'sell'].push({
                        quantity: buf2long(depth.slice(s, s + 4)),
                        price: buf2long(depth.slice(s + 4, s + 8)) / divisor,
                        orders: buf2long(depth.slice(s + 8, s + 10))
                    });
                }
            }
            ticks.push(tick);
        }
    }
    return ticks;
}

export function parseLiveTextMessage(data) {
    try {
        data = JSON.parse(data);
        return data
    }
    catch (e) {
        console.log(e, 'error dude')
        // return;
    }
    if (data.type === 'order') {
        // trigger('order_update', [data.data]);
        console.log([data.data])
        return [data.data]
    }
}


// Create a WebSocket context
export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {

    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);

    const MAX_RECONNECT_ATTEMPTS = 5; // Maximum number of reconnection attempts
    const RECONNECT_INTERVAL = 5000;  // Reconnect every 5 seconds

    const initializeWebSocket = useCallback(() => {

        const apiKey = '1kxbi1bmhbzfcuts';
        const accessToken = 'nyGCKr3s85C45Nb3oMX7gGhiHeiD8f47';
        const uid = 'asdbasjhjhsdhj'
    
        const wsUrl = `wss://ws.kite.trade/?api_key=${apiKey}&access_token=${accessToken}&uid=${uid}`;
        // console.log(wsUrl, 'ws url')
        const websocket = new WebSocket(wsUrl);
        websocket.binaryType = 'arraybuffer'
      
        websocket.onopen = () => {
            console.log('Connected to Kite WebSocket');
            // console.log(pids, 'pids sent to kite connect')
            const subscribeMessage = {
                a: 'subscribe',
                v: [5633], // Example instrument tokens
            };
      
            websocket.send(JSON.stringify(subscribeMessage));
            websocket.send(JSON.stringify({a: 'mode', v: ['full', [5633]]}));
            setIsConnected(true);
            setReconnectAttempts(0);
        };
      
        websocket.onmessage = (event) => {
        
        };
      
        websocket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
      
        websocket.onclose = () => {
            console.log('WebSocket closed, reconnecting...');
            if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
                setTimeout(() => {
                    console.log('Attempting to reconnect...');
                    setReconnectAttempts(prev => prev + 1);
                    initializeWebSocket(); // Reconnect by re-initializing the WebSocket
                }, RECONNECT_INTERVAL);
            } else {
                console.log('Maximum reconnection attempts reached.');
            }
        };

        setSocket(websocket);
    }, [reconnectAttempts])

    useEffect(() => {
        console.log('Initializing websocket dude')
        initializeWebSocket(); // Initialize WebSocket connection on component mount
        
        return () => {
            if (socket) socket.close(); // Clean up WebSocket connection on unmount
        };
    },[initializeWebSocket])

    // const [socket, setSocket] = useState(null);
    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
    // return websocket;    
};
        // const apiKey = '';
        // const accessToken = '';
        // uid = ''
    
        // const wsUrl = `wss://ws.kite.trade/?api_key=${apiKey}&access_token=${accessToken}&uid=${uid}`;
      
        // let websocket = new WebSocket(wsUrl);
        // websocket.binaryType = 'arraybuffer'
      
        // websocket.onopen = () => {
        //     console.log('Connected to Kite WebSocket');
        //     console.log(pids, 'pids sent to kite connect')
        //     const subscribeMessage = {
        //         a: 'subscribe',
        //         v: [], // Example instrument tokens
        //     };
      
        //     websocket.send(JSON.stringify(subscribeMessage));
        //     websocket.send(JSON.stringify({a: 'mode', v: ['full', []]}))
        // };
      
        // websocket.onmessage = (event) => {
        //     if (event.data instanceof ArrayBuffer){
        //         const d = parseLiveBinary(event.data)
        //         if (d && onMessageCallback){
        //             onMessageCallback(d)
        //         }
        //     } else{
        //         const d = parseLiveTextMessage(event.data)
        //         if (onMessageCallback) {
        //             onMessageCallback(d);
        //         }
        //     }
        // };
      
        // websocket.onerror = (error) => {
        //   console.error('WebSocket error:', error);
        //   if (onErrorCallback) {
        //     onErrorCallback(error);
        //   }
        // };
      
        // websocket.onclose = () => {
        //   console.log('WebSocket closed, reconnecting...');
        //   setTimeout(() => {
        //     // KiteWebSocketConnect({ apiKey, accessToken, uid, onMessageCallback, onErrorCallback });
        //   }, 5000); // Reconnect after 5 seconds
        // };

        // setSocket(websocket);

        // // Cleanup the WebSocket connection when the component unmounts
        // return () => {
        //     websocket.close();
        // };

  