import React, { useState } from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const HelpPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* Text with a question mark button beside it */}
      
        <IconButton sx={{width:20, height: 20}} aria-label="help" onClick={handleClick}>
          <HelpOutlineIcon sx={{width:20, height:20, ml:1}} />
        </IconButton>
      

      {/* Popover that appears when question mark is clicked */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          {props.coverage}
        </Typography>
      </Popover>
    </div>
  );
};

export default HelpPopover;
