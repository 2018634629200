import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Paper, Box, Typography, TextField, Grid2, ButtonGroup, Button, IconButton, Collapse, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Divider, Modal, Tabs, Tab } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import DrawCharts from "./drawCharts";
import { KiteWebSocketConnect } from "../common/liveData";


const coverageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    height: 200,
};

const SymbolEvalData = (props) => {

    let pair_id = 0;
    let ltp_value = 0;
    const [isData, setIsData] = useState(true);
    const [instrData, setInstrData] = useState();
    const [parameter, setParamter] = useState('pnl');
    const [pid, setPid] = useState('');
    const [ltp, setLtp] = useState(0);
    const [change, setChange] = useState(0);
    const [bidOpen, setBidOpen] = useState(false);
    const [chartType, setChartType] = useState('line');
    const [symbolData, setSymbolData] = useState();
    const [open, setOpen] = useState(0);
    const [high, setHigh] = useState(0);
    const [low, setLow] = useState(0);
    const [lastTradedQuantity, setLastTradedQuantity] = useState(0);
    const [averageTradedPrice, setAverageTradedPrice] = useState(0)
    const [openInterest, setOpenInterest] = useState(0);
    const [tradedVolume, setTradedVolume] = useState(0);
    const [oiDayLow, setOIDayLow] = useState(0);
    const [oiDayHigh, setOIDayHigh] = useState(0);
    const [bidData, setBidData] = useState([]);
    const [askData, setAskData] = useState([]);
    const [stockBeta, setStockBeta] = useState(0);
    const [portfolioActionOpen, setPortfolioActionOpen] = useState(false);
    const [ws, setWs] = useState();
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [backtestSeriesData, setBacktestSeriesData] = useState([]);
    const [backtestLabelData, setBacktestLabelData] = useState([]);
    const [backtestDuration, setBacktestDuration] = useState('yearly')
    const [coverageOpen, setCoverageOpen] = useState(false);
    const [coverageData, setCoverageData] = useState('');
    const [responseData, setResponseData] = useState();
    const [graphTabsValue, setGraphTabsValue] = useState(0);

    const handleGraphTabChange = (event, newValue) => {
        console.log(newValue, 'new value');
        setGraphTabsValue(newValue);
    };

    const handleCoverageOpen = () => setCoverageOpen(true);
  
    const handleCoverageClose = () => setCoverageOpen(false);

    const handleBidToggle = () => {
        setBidOpen(prevBidOpen => !prevBidOpen); // Toggle the open state
    };

    const handleAddPortfolio = () => {
        const data = {'symbol' : props.symbol, 'price': ltp, 'coverage' : coverageData}
        if (!props.formData.selected_stocks.includes(data)){
            const symbolList = [...props.formData.selected_stocks, data];
            props.handleInputChange({['selected_stocks']: symbolList})
        }
    }
    const handleDropdownItemClick = (event) => {
        setDropDownVisible(!dropDownVisible);
    }

    const handleCoverageSubmit = () => {
        const data = document.getElementById('stockCoverage').value
        setCoverageData(data);
        setCoverageOpen(false);
    }
    useEffect(() => {
        console.log('pid updated broooo')
    },[pid])

    useEffect(() => {
        getStockData(props).then(() => {
            const websocket = KiteWebSocketConnect({
                apiKey: '1kxbi1bmhbzfcuts',
                accessToken: 'vkQyXDS1E9bIfjoGbEDV7oPtCq6eC0B1',
                uid: 'asdbasjhjhsdhj',
                pids: [pair_id],
                onMessageCallback: (message) => {
                    // console.log('Message received in App:', message);
                    // console.log(message.length, 'message length br');
                    if (message.length){

                        message.forEach((data) => {
                            // console.log(data['instrument_token'], 'instrument token dude');
                            // console.log(pair_id, 'pid bro')
                            
                                ltp_value = data['last_price']
                                // console.log(ltp_value, 'ltp value dde');
                                
                                setLtp(data['last_price'])
                                setOpen(data['ohlc']['open'])
                                setLow(data['ohlc']['low'])
                                setHigh(data['ohlc']['high'])
                                setChange(data['change'].toFixed(2))
                                setAskData(data['depth']['sell'])
                                setBidData(data['depth']['buy'])
                                setTradedVolume(data['volume_traded'])
                                setAverageTradedPrice(data['average_traded_price'])
                                setLastTradedQuantity(data['last_traded_quantity'])
                                setOpenInterest(data['oi']);
                                setOIDayHigh(data['oi_day_high'])
                                setOIDayLow(data['oi_day_low'])
                            // // }
                        }) 
                    }
                },
                onErrorCallback: (error) => {
                    console.error('WebSocket error in App:', error);
                }
            });
            setWs(websocket)
            return () => {
                if (websocket) {
                    websocket.close();
                 }
            };
        })
        //Cleanup WebSocket connection on unmount
    }, [pair_id, ltp_value]);

    // useEffect(()=> {
    //     getStockData(props);
    // },[])
    // useEffect(()=>{
    //     // console.log('trying to update ws immediately')
    //     if (ws){
    //         console.log('Updating pairid in websocket')
    //         ws.send(JSON.stringify({a: 'subscribe', v: [pair_id]}))
    //         ws.send(JSON.stringify({a: 'mode', v: ['full', [pair_id]]}))
    //     }
    // },[ws])
    const getStockData = async (props) => {

        const symbol = props.symbol
        const formData = {
            symbol : symbol 
        }
        const symbolFormData = {
            asset : 'equity'
        }
        const symbols_res = await axios.get('http://127.0.0.1:5000/portfolio/get_symbols',{params: symbolFormData})
        const instr_data = symbols_res.data.instr_data
        setInstrData(instr_data)
        const res = await axios.post('http://127.0.0.1:5000/portfolio/get_graph_data_single', formData)
        const symbol_data = res.data[symbol][parameter]
        // console.log(symbol_data, 'symbol data from api')
        // console.log(Object.keys(symbol_data).length, 'length')
        // if (! Object.keys(symbol_data).length === 0) {
            const ohlc_data = res.data.ohlc_data
            setResponseData(res.data[symbol])
            setStockBeta(res.data[symbol]['beta_value'])
            // console.log(symbol_data, 'symbol data')
            const series_data = [{data: symbol_data[backtestDuration]['data'], name: symbol}]
            setSymbolData({'symbol': symbol,'data': symbol_data});
            setBacktestSeriesData(series_data);
            setBacktestLabelData(symbol_data['monthly']['labels'])
            setPid(instr_data[symbol]);
            pair_id = instr_data[symbol]
            console.log(pair_id, 'pair id in get stock data')
            if (ws){
                // console.log('ws present, trying to subscribe')
                ws.send(JSON.stringify({a: 'subscribe', v: [instrData[symbol]]}))
                ws.send(JSON.stringify({a: 'mode', v: ['full', [instrData[symbol]]]}))
            } else {
                console.log('ws isnt updated')
            }
        // } else {
        //     console.log('No data found')
        //     setIsData(false);
        // }
    }

    function GraphTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      GraphTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };


    return (
        <>
            <Paper sx={(theme) => ({height: 580, width: 850, backgroundColor: '#fff', ...theme.applyStyles('dark', {backgroundColor: '#1A2027',}), overflowY: 'auto', overflowX:'hidden'})}>
                <Box width={'100%'} height={80} pt={2} display={'flex'}>
                    <Box width={'50%'} pl={3}>
                        <Typography variant="h4" >{props.symbol}</Typography>
                        <Grid2 container>
                            <Typography variant='h6' id={"ltp-"+pid} >
                                {ltp}
                            </Typography>
                            { change > 0 ? 
                                <Typography variant='h6' ml={1} color={'#007c32'} id={"netChange-"+pid}>{change}<ArrowUpwardIcon sx={{width: 20}}></ArrowUpwardIcon></Typography>
                                :
                                <Typography variant='h6' ml={1} color={'red'} id={"netChange-"+pid}>{change}<ArrowDownwardIcon sx={{width: 20}}></ArrowDownwardIcon></Typography>
                            }
                        </Grid2>
                    </Box>
                    <Box width='50%'>
                        <Grid2 container justifyContent={'flex-end'} gap={2} ml={-2}>
                            {/* <IconButton sx={{borderRadius: '50%', backgroundColor: '#fffff', '&:hover': { backgroundColor: '#e0e0e0',}, p: 1}} aria-label="menu">
                                <MenuRoundedIcon onClick={handleBidToggle} />
                            </IconButton> */}
                            <Button variant="contained" ml={-5} onClick={handleAddPortfolio}>Add to Portfolio</Button>
                        </Grid2>
                    </Box>
                </Box>
                    <Box width={'100%'}>
                        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                            <Tabs value={graphTabsValue} onChange={handleGraphTabChange} aria-label="disabled tabs">
                                <Tab label="Graphs" />
                                <Tab label="Micro Data" />
                                <Tab label="News" />
                                <Tab label="Predictions" />
                            </Tabs>
                        </Box>
                        <GraphTabPanel value={graphTabsValue} index={0}>
                            <Box width={'100%'} height={300}>
                            {/* {isData ?  */}
                                <DrawCharts graphType={'pnl'} seriesData={backtestSeriesData} setBacktestDuration={setBacktestDuration} labelData={backtestLabelData} chartType={chartType} handleDropdownItemClick={handleDropdownItemClick} setChartType={setChartType} DropDownVisible={dropDownVisible}></DrawCharts>
                                {/* :
                                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                    <Typography variant="subtitle">No Data to Show</Typography>
                                </Box>
                            } */}
                            </Box>
                        </GraphTabPanel>
                        <GraphTabPanel value={graphTabsValue} index={1}>
                        <Box sx={{ width: '100%', height: 200, mt: 2, p: 2, display: 'flex', gap:2, transition: 'opacity 0.5s ease',}}>
                            <Box width={'30%'}>
                                <TableContainer component={Paper} sx={{width: '100%', overflow: 'hidden'}}>
                                    <Table size='small' aria-label="Bids Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>BID</TableCell>
                                                <TableCell align="right">Orders</TableCell>
                                                <TableCell align="right">Qty</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {bidData.map((value, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{value['price']}</TableCell>
                                                        <TableCell align="right">{value['orders']}</TableCell>
                                                        <TableCell align="right">{value['quantity']}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box width={'30%'}>
                                <TableContainer component={Paper} sx={{width: '100%', maxHeight:'100%', overflow:'hidden'}}>
                                    <Table size='small' aria-label="Asks Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ASK</TableCell>
                                                <TableCell align="right">Orders</TableCell>
                                                <TableCell align="right">Qty</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {askData.map((value, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{value['price']}</TableCell>
                                                        <TableCell align="right">{value['orders']}</TableCell>
                                                        <TableCell align="right">{value['quantity']}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box width={'30%'}>
                                <TableContainer component={Paper} sx={{width: '100%', maxHeight:'100%', overflow:'hidden'}}>
                                    <Table size='small' height={200} aria-label="Asks Table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Last Traded Qty: </TableCell>
                                                <TableCell>{lastTradedQuantity}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Avg Trade Price: </TableCell>
                                                    <TableCell>{averageTradedPrice}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Volume: </TableCell>
                                                <TableCell>{tradedVolume}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Open Interest: </TableCell>
                                                <TableCell>{openInterest}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>OI Day High: </TableCell>
                                                <TableCell>{oiDayHigh}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>OI Day Low: </TableCell>
                                                <TableCell>{oiDayLow}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            </Box>
                        </GraphTabPanel>
                        <GraphTabPanel value={graphTabsValue} index={2}>
                            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                            <Box width={'60%'} >
                        <Typography variant="h5">News</Typography>
                        <TableContainer component={Paper} sx={{maxHeight:200, maxWidth:'100%', overflow:"hidden", borderRadius:2}}>
                            <Table size='small' sx={{maxWidth: '100%', maxHeight:200, overflow:"hidden"}} aria-label='Bids Table'>
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Typography variant='body'><StarOutlineIcon sx={{width:15, height:15}}></StarOutlineIcon>{"Share price of "+props.symbol+" jumps as Sensex drops 947.58 points"} </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>
                                            <Typography variant='body'><StarOutlineIcon sx={{width:15, height:15}}></StarOutlineIcon>{props.symbol+" shares rise 2.63 per cent in Thursday's trading session"}</Typography>
                                        </TableCell>
                                    </TableRow> 
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> 
                                        <TableCell>
                                            <Typography variant='body'><StarOutlineIcon sx={{width:15, height:15}}></StarOutlineIcon>{props.symbol+" shares up 0.34% as Nifty falls"} </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                            </Box>
                        </GraphTabPanel>
                        <GraphTabPanel value={graphTabsValue} index={3}>
                            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                <Box width={'30%'}>
                                    <Typography variant="h5">Predictions</Typography>
                                    <TableContainer component={Paper} sx={{maxHeight:200, maxWidth:'100%', overflow:"hidden", borderRadius:2}}>
                                        <Table size='small' sx={{maxWidth: '100%', maxHeight:200, overflow:"hidden"}} aria-label='Bids Table'>
                                            <TableBody>
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>
                                                        <Typography ml={1} sx={{fontSize: '10', fontWeight: '600'}}>Technical Analysis:</Typography>
                                                        <Typography ml={3} sx={{fontSize: '12', fontWeight: '500', color: '#007c32'}}>Strong Buy</Typography>
                                                        <Divider></Divider>
                                                        <Typography ml={1} sx={{fontSize: '10', fontWeight: '600'}}>Analyst Sentiment:</Typography>
                                                        <Typography ml={3} sx={{fontSize: '12', fontWeight: '500', color: '#d91400'}}>Strong Sell</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </GraphTabPanel>
                    </Box>
                <Box width={'100%'} height={50}>
                    <Grid2 container justifyContent={'flex-end'} gap={2} ml={-2}>
                        <Button variant="contained" ml={-5} onClick={handleCoverageOpen}>Add Coverage</Button>
                    </Grid2>
                </Box>
                <Box width={'100%'} height={200}>
                    <TableContainer component={Paper} sx={{maxHeight:200}}>
                        <Table stickyHeader sx={{minWidth: 650}} aria-label='stocks table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Stock Name</TableCell>
                                    <TableCell align="right">LTP</TableCell>
                                    <TableCell align="right">Open</TableCell>
                                    <TableCell align="right">High</TableCell>
                                    <TableCell align="right">Low</TableCell>
                                    <TableCell align="right">Change(%)</TableCell>
                                    <TableCell align="right">&beta;</TableCell>
                                    <TableCell align="right">PNL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{props.symbol}</TableCell>
                                    <TableCell align='right'>{ltp}</TableCell>
                                    <TableCell align="right" id={'open-'+pid}>{open}</TableCell>
                                    <TableCell align="right" id={'high-'+pid}>{high}</TableCell>
                                    <TableCell align="right" id={'low-'+pid}>{low}</TableCell>
                                    {change > 0 ?
                                        <TableCell align="right" id={'change-'+pid} sx={{color:'green'}}>{change+'%'}</TableCell>
                                        :
                                        <TableCell align="right" id={'change-'+pid} sx={{color:'red'}}>{change+'%'}</TableCell>
                                    }
                                    <TableCell align="right" id={'beta-'+pid}>{stockBeta}</TableCell>
                                    <TableCell align="right" id={'pnl-'+pid}>6.6</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box width={'100%'}>

                </Box>
                <Modal open={coverageOpen} onClose={handleCoverageClose} aria-labelledby="coverage-modal" aria-describedby="coverage-modal-description">
                <Box sx={coverageStyle}>
                    <TextField id='stockCoverage' label='Stock Coverage' multiline rows={4} fullWidth></TextField>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} mt={2}>
                        <Button variant="contained" float='right' size='small' onClick={handleCoverageSubmit}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
            </Paper>
        </>
    )
}

export default SymbolEvalData