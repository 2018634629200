import React, { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import myLocalStorage from '../utils/shared_utils';
import { toast } from 'react-toastify';
import axios from "axios";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const steps = [
    'Email Verification',
    'Terms and Conditions',
    'User Verification',
    'Risk Profiling',
    'Curated Portfolios'
  ];

function TermsVerify(props){
    const [isTermsValidated, setIsTermsValidated] = useState(false)
    const [isDataLoading, setIsDataLoading] = useState(false);
    const handleClose = () => {
        setIsDataLoading(false);
    };
    if (isTermsValidated){
        props.nextStep()
    }
    const onSubmit = async () => {
        setIsDataLoading(true);
        const email_id = myLocalStorage.getItem('email_id')
        console.log(email_id, ' email id in terms')
        const formData = {'email_id': email_id}
        try{
            // await axios.post("http://localhost:5000/userRegister/accept_terms?email_id="+email_id);
            await axios.post("http://localhost:5000/userRegister/accept_terms", formData);
            setIsTermsValidated(true)
            toast.success('Terms and conditions Accepted')
            props.nextStep();
        } catch(err){
            if (!err.response){
                console.log(err)
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
        }
        setIsDataLoading(false);
        // props.nextStep();
    }
    return (
        <>
        {isDataLoading?
        <Backdrop sx={(theme) => ({ color: '#000', zIndex: theme.zIndex.drawer + 1, backgroundColor:'#fff' })} invisible={'true'} open={isDataLoading} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop> :
      <div>
            <Box sx={{ width: '80%'}} mt={10} ml={15}>
                <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </Box>
            <div className='terms-box'>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" 
integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4" 
crossorigin="anonymous"/>
                <div >
                <p>
                <p class='title1'>Hedgeloop - Terms and Conditions</p>
                <p class="p3"><strong>Section A &ndash; Important Information</strong></p>
<p class="p5">This document contains the terms and conditions for certain services that we, Hedgeloop, provide to you:</p>
<p class="p8"><span class="s1"><strong>The Terms and Conditions</strong></span></p>
<ol>
    <li class="p8">These Hedgeloop Terms and Conditions together with:</li>
    <ul class="ol2">
        <li class="li12"><span class="s1">the Advisory Services Application Form</span></li>
        <li class="li12"><span class="s1">the Hedgeloop General Terms and Conditions if any;</span></li>
        <li class="li12"><span class="s1">each additional Services Application Form and/or corresponding Services Terms and Conditions, if any;</span></li>
        <li class="li12"><span class="s1">the Power of Attorney, if any, executed by you in our favour; and</span></li>
        <li class="li12"><span class="s1">any other information on our charges, commissions and tariffs&nbsp;</span><span class="s1">form the terms and conditions defining our relationship with you in relation to the Services you select by signing the Advisory Services Application Form and each Services Application Form and/or Services Terms and Conditions (collectively referred to as <strong>&ldquo;Terms and Conditions&rdquo;</strong>).</span></li>
    </ul>
    <li class="p17">If these Terms and Conditions are inconsistent with any of the terms outlined in the documents listed in points the points above, the Terms and Conditions will prevail.</li>
</ol>
<p class="p1"><span class="s1"><strong>When the Terms and Conditions comes into effect</strong></span></p>
<ol class="ol1">
    <li class="li3"><span class="s1">The Terms and Conditions come into effect when you apply for and/or we start providing the Service(s) to you.</span></li>
    <li class="li5"><span class="s1">These Terms and Conditions apply only to Advisory Services provided by Hedgeloop. If you wish to be provided with any other services, you may be asked to sign the appropriate Services Application Form and/or Services Terms and Conditions, which will apply to our relationship with you in addition to these Advisory Services Terms and Conditions.</span></li>
    <li class="li5"><span class="s1">It is important that you read the Terms and Conditions carefully. You should keep a copy in a safe place.</span></li>
</ol>
<p class="p1"><span class="s1"><strong>Meaning of words and expressions</strong></span></p>
<ol class="ol1">
    <li class="li3"><span class="s1">The meanings of certain words used in these Terms and Conditions are set out in Section C. Also, in these Terms and Conditions:</span></li>
</ol>
<p><span class="s1"><strong>we</strong>,<strong> us </strong>and<strong> our </strong>means Hedgeloop which provides the Services to you as specified in the Advisory Services Application Form, the Terms&nbsp;</span>and Conditions, any Services Application Form and corresponding Services Terms and Conditions signed by you; and&nbsp;</p>
<p><strong>you </strong>and<strong> your </strong>mean any person entering these Terms and Conditions with us, which expressions shall, unless repugnant to the context or meaning thereof, be deemed to mean and include, if you are an individual, your heirs, executors, administrators and legal representatives; if you are a sole proprietorship firm, your heirs, legal representatives, executors, administrators and permitted assigns; if you are a partnership firm or limited partnership firm, the partners or partner for the time being of the partnership firm or limited partnership firm, the survivors or survivor of them, their respective heirs, executors and administrators; if you are a company, your successors and permitted assigns; if you are a Hindu Undivided Family (HUF), its Karta and its co-parceners and the survivors or survivor of them and their respective heirs, legal representatives, executors, administrators and permitted assigns; and if you are a trust, the trustees for the time being of the trust, survivors or survivor of them and the heirs, executors and administrators of the last surviving trustee.&nbsp;</p>
<p>You confirm that the Services envisaged under the Terms and Conditions are personal to you and rendered to you in your individual capacity and do not extend to any person with whom you may jointly hold the Assets unless such joint Asset holder has submitted to us the Advisory Services Application Form, the relevant Services Application Form (if any) and accepted these Terms and Conditions and the corresponding Services Terms and Conditions in the form and manner prescribed by us. If you have any doubts with respect to the Asset jointly held with one or more persons or any aspect of these Terms and Conditions you should raise them with your Relationship Manager.&nbsp;</p>
<p class="p1"><strong><span class="s1">Section B &ndash; The services we provide</span></strong></p>
<p class="p2"><strong>Advisory Services</strong></p>
<ol>
    <li><span class="s1">Where we agree to provide you with our Advisory Services, the provisions of this Clause 2 will apply.</span></li>
    <li><span class="s1">We may at your request inform you of Investment opportunities and give advice on them .</span></li>
    <li><span class="s1"><span class="s1">We may, at your request, advise or recommend to you on a range of transactions and Investments. Unless specifically requested by you and explicitly agreed by us, we have no ongoing obligation to advise you on, or to monitor, any individual Investment or portfolio of Investments held with us or otherwise. Unless we expressly state that a transaction or Investment is advised by us, transactions or&nbsp;</span></span>Investments we inform you shall be on a non-advised basis only. When giving you advice, we are not obliged to consider any research reports that we have previously provided to you.</li>
    <li><span class="s2">When, at your request, we recommend or advise on Investment opportunities either in writing or otherwise, we shall provide such recommendation or advice as per standards prescribed in the applicable Regulations, which presently require us to have reasonable basis to believe that such recommendation or advice:</span></li>
</ol>
<ol class="ol1">
    <ol class="ol2">
        <li class="li3"><span class="s2">meets your investment objectives as may be recorded with us and confirmed by you;</span></li>
        <li class="li3"><span class="s2">that you are able to bear any investment risk consistent with your investment objectives and risk tolerance; and</span></li>
        <li class="li3"><span class="s2">that you have the necessary experience and knowledge to understand the risks involved in the Investment(s).</span></li>
    </ol>
    <li><span class="s2">Notwithstanding our advice or recommendation, your decision, action or omission to buy, sell, hold or otherwise deal in the Investments shall be based on your own independent evaluation of the risks and rewards of the Investments and your own verification of all the relevant facts, including financial and other circumstances and a proper evaluation thereof.</span></li>
    <li>You understand that we do not and will not provide you with legal or tax advice and no advice or recommendation will be construed as such. We recommend that you obtain your own independent legal and tax advice, tailored to your particular circumstances.</li>
    <li><span class="s2">Please note that we will not ordinarily, pursuant to these Advisory Services Terms and Conditions, undertake the management or administration of your funds or your portfolio of Securities, on your behalf. If however you would like us to undertake the management or administration of your funds or your portfolio of Securities on your behalf on a discretionary or non-discretionary basis, then you will be asked to sign specific documentation dealing with such services</span></li>
</ol>
<p class="p1"><span class="s1">We may, at your request, refer you to service providers, stock brokers or other intermediaries or distributors (collectively hereinafter referred to as <strong>&ldquo;Intermediaries&rdquo;</strong>) who may facilitate or provide services to enable you to implement any advice or recommendation made by us in respect of Investment products. Where any consideration by way of remuneration or compensation or in any other form whatsoever, if any, is received or receivable by us from such Intermediaries, we shall disclose the same to you to prior to&nbsp;</span>referring you to such Intermediaries. You confirm that you shall independently evaluate the services and capabilities of the Intermediaries and you shall enter into a contract for services at your sole discretion, judgment and opinion.</p>
<ol class="ol1">
    <li class="li1"><span class="s2">We may at your request, but not obliged to, agree to act as your agent under a duly executed power of attorney to implement any advice or recommendation made by us in respect of Investment products through the Intermediaries. Where we have agreed to act as your agent, we shall buy, sell or otherwise deal in Securities through one or more Intermediary of your choice only as per your instruction. We may require you to execute additional documents before we agree to act as your agent.</span></li>
    <li class="li1"><span class="s2">You agree that the decision to execute such transactions, whether directly or through us as your agent and through any Intermediary is at your sole discretion, judgment and opinion. You further undertake and confirm that you shall be responsible for all the investment decisions and trades executed through such Intermediaries. We shall not be held liable in respect of services rendered by the Intermediaries.</span></li>
</ol>
<p>&nbsp;<strong>Investment research and analysis</strong>&nbsp;</p>
<ol class="ol1">
    <li class="li3"><span class="s2">We may, at your request, give you information or views on Investments or markets such as research reports, focus lists, market trends, investment analysis or commentary on the performance of selected companies (collectively referred to as <strong>&ldquo;Publication/(s)&rdquo;</strong>), to the extent permitted by the relevant laws and regulations.&nbsp;</span></li>
    <li class="li3"><span class="s2">The information contained therein is intended for general circulation and you confirm your understanding that:</span>&nbsp;&nbsp;
        <ol>
            <li class="li3"><span class="s2">The Publication/(s) are prepared for persons who have experience in matters related to Investments or are investment professionals with sufficient financial sophistication to be able to appraise and evaluate the information received or accessed, including, where appropriate, a full understanding of the credit risks inherent in new debt issues and the price volatility of stocks when brought to the market. The Publication/(s) may not be suitable for all investors;</span></li>
            <li class="li3">You should consider whether you can rely upon the contents of these Publication/(s) without seeking further advice tailored for your personal objectives, financial situation or needs;</li>
            <li class="li3">Investments are subject to market risk. Please refer to the offering documents or information memorandum and the specific risk factors before investing. Unless expressly stated, products mentioned in the Publication/(s) are not guaranteed by Hedgeloop or any government entity;</li>
        </ol>
    </li>
    <li><span class="s2">Publication/(s) may use modeling methodologies and statistical techniques with which we assume you are familiar;</span></li>
    <li><span class="s2">Nothing in the Publication/(s) shall constitute an investment recommendation made by us nor is it intended to form the sole basis for any evaluation of the Securities or any other instrument which may be discussed in it or any investment decision. Unless there is an explicit and recorded recognition on our part, in relation to a particular transaction, that investment advice is being offered on the basis of such Publication/(s), you acknowledge that any such investment decisions you make in relation to the Publication/(s), will not be treated as advised by us. Save as stated above, in every other circumstance, you acknowledge that you will assume responsibility for any investment decisions you make further to the information contained in the Publication/(s). Unless required by applicable laws and regulations (if any), neither us nor any our Associates, nor any of our respective officers, directors, partners, or employees accepts any liability whatsoever for any Losses arising from any use of or reliance upon the Publication/(s) or its contents, or for any omission;</span></li>
    <li><span class="s2">There may be interruptions to our Publication/(s) service. We reserve the right to change or discontinue the service at any time without prior notice (unless prohibited by the applicable laws and regulations);</span>You agree that you will not copy, distribute, sell or in any way disclose the Publication/(s) to any third parties without our prior written consent;</li>
    <li>You shall fully reimburse us for all Losses incurred by us as a result of your copying, distributing, selling or disclosing the Publication/(s) to any third parties in contravention of sub-clause (f) above;</li>
    <li><span class="s2">You understand that we may source and/or distribute contents of such Publication/(s) (in whole or part) through others. It would not be construed as creating a client relationship between you and others and that you shall not contact others. Should you have any queries in respect of matters arising from, or in connection with such Publication/(s) you should contact your Relationship Manager;</span></li>
</ol>
<p class="p1"><span class="s1">While the views expressed in the Publication/(s) would generally be ours as of the date of the Publication/(s), some or all of the views contained therein may be based on the views of one or several of our sources. All opinions, estimates and projections provided by such sources or the author/(s) are as of the date of such </span>Publication/(s) and are subject to change without notice and we have no obligation to update any views, opinion, estimates or projections set forth therein; and</p>
<p class="p1">We believe the information contained in the Publication/(s) is reliable; however we make no representation as to the accuracy or completeness of information contained in such Publication/(s) which is stated to have been obtained from or is based upon trade and statistical services or other third party sources. Any data on past performance, modeling or back-testing contained therein is no indication as to future performance. No representation is made as to the reasonableness of the assumptions made within or the accuracy or completeness of any modeling or back-testing. The value of any investment may fluctuate as a result of market changes. There can be no assurance or guarantee that any Investment will achieve any particular return. Past performance is not necessarily an indicator of the future performance of any Investment.</p>
<p class="p1">You acknowledge that you are aware of the basis on which the Publication/(s) is provided to you as detailed above and you agree to receive it on that basis and treat it accordingly.</p>
<p class="p2">You understand that in case you wish to discontinue receiving Publication/(s) at any time, you can write to us or contact your Relationship Manager.</p>
<p class="p2"><strong>Your obligations</strong>&nbsp;</p>
<ol class="ol1">
    <li class="li3"><span class="s2">If we provide you with any recommendations or advice in relation to Investments, you shall not disclose any such recommendations or advice provided by us to you pertaining to the purchase and sale of Securities to any third party and we shall not be responsible for any Losses that may be suffered by such party as a result of the disclosure of such recommendations or advice by you. No third party shall place any reliance on the information furnished to you. Any recommendations or advice provided is exclusively for your knowledge and use, subject to the extent otherwise permitted herein. You shall not disclose the source of information to any third party, except to relevant governmental and/or statutory authorities on demand.</span></li>
    <li class="li3"><span class="s2">You confirm that you understand the risks involved in investing in Investments including Securities.</span></li>
    <li class="li3"><span class="s2"><span class="s2">You agree to provide any information we may reasonably request from time to time, in order to enable us to perform our Services under the Terms and Conditions or comply with any laws, regulations and policies (including, without&nbsp;</span></span>limitation, &ldquo;know your client&rdquo; regulations/policies). You undertake to notify us promptly of any material change to information provided by you to us in connection with the Advisory Services Application Form, any other Service Application Form(s) or your Accounts or Investments purchased by you, including any change in the details stated in your Client Profilers. You understand and accept that failure to do so may adversely affect the quality of the advice or recommendations or any Service envisaged in the Terms and Conditions. An illustrative list of factors that may materially impact our ability to render Services effectively are as follows: <br />
        <ol>
            <li class="li3"><span class="s2">Any change in circumstances that may lead to a change in your risk appetite or risk tolerance;</span></li>
            <li class="li3"><span class="s2">Your investment objectives including time for which you wish to stay invested;</span></li>
            <li class="li3"><span class="s2">The purposes of the Investments;</span></li>
            <li class="li3"><span class="s2">Any restrictions or preferences that you may wish to specify in respect of the nature or manner of Investments or on any particular security/sector;</span></li>
            <li class="li3"><span class="s2">Your income details;</span></li>
            <li class="li3"><span class="s2">Your liabilities details; and</span></li>
            <li class="li3"><span class="s2">Your existing Investments and assets including those not advised by us</span></li>
        </ol>
    </li>
    <li><span class="s2">You understand and acknowledge that if you do not follow our recommendation or advice or where you have imposed any restrictions or specified any preferences in respect of the nature or manner of Investments or on any particular security/sector, then you may not achieve your investment objectives and that the performance of your Assets may be negatively impacted.</span></li>
    <li><span class="s2">You acknowledge that the Hedgeloop does not provide execution services and you shall at your discretion, judgment and opinion choose the Intermediary through whom you wish to execute our advice or recommendation. You acknowledge and confirm that for the purposes of executing such advice or recommendation you shall accordingly contract with such Intermediary on a principal to principal basis.</span>
        <ol>
            <li><span class="s2">(a) You consent to Account(s) to be opened by us in your name for the purpose of recording information pertaining to your financial circumstances and investment objectives including details of your Assets that you have disclosed to us for rendering Services envisaged under the Terms and Conditions and/or for recording Assets acquired under our advice or recommendation. You understand that an Account is merely a means to segregate the Assets and/or transactions recorded by us in the manner we consider appropriate and are for administrative purposes only.</span></li>
            <li><span class="s2">Where Assets disclosed to us pursuant to sub-clause (a) above are held by you jointly with one or more persons, you consent to the Account(s) to be opened in the name of the first named holder of such Assets.</span>&nbsp;</li>
            <li>Notwithstanding sub-clauses (a) and (b) above, you agree and accept that you along with the other joint<span class="s2">owner(s), where applicable, shall be responsible and liable for the physical custody of the Assets. You understand and accept that the Account(s) opened by us are not for the purpose of custody of the Assets and we are not responsible for holding the Assets in our custody.</span>&nbsp;</li>
        </ol>
    </li>
</ol>
<p><span class="s2"><strong>Our liability and responsibilities to you</strong></span></p>
<ol class="ol1">
    <li class="li3"><span class="s2">We will attend to your affairs with due care and diligence, but we will not be liable for the performance or profitability of Investments we recommend for you. All decisions in relation to Investments are based on your own evaluation of your financial circumstances and investment objectives. Any decision, action or omission to buy, sell or hold Investments shall be based solely on your own verification and a proper evaluation of all the relevant facts, financials and other circumstances and neither we nor any of our employees, officers, directors, personnel, agents or representatives or Associates shall be responsible or held liable for the same for any reason whatsoever. You are aware that the value of the Investments could substantially depreciate to an unpredictable extent.</span></li>
    <li class="li3"><span class="s2">Where we, have agreed to provide you with advice or recommendation with respect to any Investment, we shall adhere to the following responsibilities and obligations determined by the Regulations:</span></li>
</ol>
<ol class="ol1">
    <ol class="ol2">
        <li class="li10"><span class="s2">We shall act in a fiduciary capacity and shall disclose all conflicts of interests as and when they arise and where appropriate, obtain your consent;</span></li>
        <li class="li10">We shall not receive any consideration by way of remuneration or compensation or in any other form from any person other than from you the client being advised, in respect of the underlying products or Securities for which advice is provided;</li>
        <li class="li10">We shall maintain an arms-length relationship between our activities as an Adviser and other activities, which Hedgeloop may carry on from time to time;</li>
        <li class="li10">Where Hedgeloop is engaged in activities other than Advisory Services, we shall ensure that the Advisory Services are clearly segregated from all other activities of Hedgeloop in the manner as prescribed by the Regulations;</li>
        <li class="li10">Save as permitted under law we shall not divulge any confidential information about you, which you have disclosed to us without obtaining prior consent from you;</li>
        <li class="li10">We shall not enter into transactions on our own account which is contrary to the advice we have given you for a period of fifteen days from the date of such advice to you. Provided that during the period of such fifteen days, if we are of the opinion that the situation has changed, then we may enter into such a transaction on our own account after giving you the revised advice at least 24 hours in advance before entering into such transaction;</li>
        <li class="li10"><span class="s2"><span class="s2">We shall abide by the Code of Conduct as specified in Third Schedule to the SEBI (Investment Advisers) Regulations,2013 as may be amended,&nbsp;</span></span><span class="s2"><span class="s2">supplemented or replaced from time to time; and </span></span><span class="s2">We shall not act on our own account (on a principal to principal basis), knowingly to sell Securities or Investment products to or purchase Securities or Investment product from you in respect of which we are rendering Advisory Services envisaged under these Terms and Conditions acting in a fiduciary capacity</span></li>
    </ol>
    <li><span class="s2">You confirm that you are aware that Securities are subject to a very wide variety of risks which include amongst others (and by way of illustration) an unpredictable loss in value which may extend to a total loss of value of the Securities due to, inter alia:</span></li>
    <li><span class="s2">overall economic slowdown, unanticipated corporate performance, environmental or political problems, changes to monetary or fiscal policies, changes in government policies and regulations with regard to industry and exports;</span></li>
    <li><span class="s2">acts of force majeure including nationalisation, expropriation, currency restriction, measures taken by any government or agency of any country, state or territory in the world, industrial action or labour disturbances of any nature, boycotts, power failures or breakdowns in communication links or equipment (including but not limited to loss of electronic data) international conflicts, violent or armed actions, acts of terrorism, insurrection, revolution, nuclear fusion, fission or radiation, or acts of God, default of courier or delivery service or failure or disruption of any relevant stock exchange, depository, clearing house, clearing or settlement systems or market, or the delivery of fake or stolen securities;</span></li>
    <li><span class="s2">de-listing of Securities or market closure, relatively small number of scrip accounting for a large proportion of trading volume;</span></li>
    <li><span class="s2">limited liquidity in the stock markets impeding readjustment of portfolio composition;</span></li>
    <li><span class="s2">volatility of the stock markets, stock market scams, circular trading of securities and price rigging;</span></li>
    <li>default or non-performance of a third party, company&rsquo;s refusal to register a Security due to legal stay or otherwise and disputes raised by third parties; and</li>
    <li><span class="s2">low possibilities of recovery of loss due to expensive and time consuming legal process and any changes in the Securities and Exchange Board of India (SEBI) rules and regulations and other applicable laws governing the Terms and Conditions.</span></li>
</ol>
<p><span class="s2"><strong>Conflicts of interest and material interest</strong></span></p>
<ol class="ol1">
    <ol class="ol1">
        <li class="li1"><span class="s2">Below is a non-exhaustive list of some types of conflicts of interest that could arise so that you are able to understand them and consent to our acting nonetheless. Examples of such situations include:</span></li>
    </ol>
</ol>
<ol class="ol1">
    <ol class="ol1">
        <ol class="ol2">
            <li class="li1"><span class="s2">where recommendations we make to you differ from advice or recommendations given to other clients by us or our Associates;</span></li>
            <li class="li1"><span class="s2">where a recommendation involves Investments issued by Hedgeloop, an Associate, a client or a client of an Associate;</span></li>
            <li class="li1"><span class="s2">where we effect, arrange or give advice on transactions in which we or an Associate benefit from a commission, fee, mark-up or mark-down payable otherwise than by you and/or in respect of which we or an Associate may also be remunerated by the counterparty to the transaction.</span></li>
            <li class="li1"><span class="s2">where we recommend units in a Mutual Fund or investment trust company in respect of which we or our Associates are the trustee, investment manager or operator (or an adviser of the trustee, investment manager or operator) of the Mutual Fund or investment trust company or otherwise act in a similar capacity; and</span></li>
            <li class="li1"><span class="s2">where our officers or employees or those of our Associates act as officers or employees of issuers of Investments in respect of which we make recommendations.</span></li>
        </ol>
    </ol>
</ol>
<p><span class="s2"><strong>Maintenance of your records and statement of accounts</strong></span></p>
<ol class="ol1">
    <ol class="ol1">
        <li class="li1"><span class="s2">In respect of your Account(s) with us, we will maintain records of Assets and/or transactions in respect of which we have provided advice. Where any transactions have been executed through other divisions of BSIPL or other Associates, you authorize us to receive such information from such other division or Associate to enable us to update our record of your Assets.</span></li>
        <li class="li1"><span class="s2">We may, but not obliged to do so, at your request maintain a record of whole or part of your Investments held with service providers other than Hedgeloop and hereinafter collectively referred to as <strong>&ldquo;Held-Away Assets&rdquo;</strong>.</span></li>
        <li class="li1"><span class="s2">Unless otherwise agreed by us in writing or required by law, you agree that with respect to the statements or reports that we may provide to you, we have the discretion to consider such parameters, make such assumptions and prepare in such format as we deem fit.</span></li>
        <li class="li1"><span class="s2">You confirm that you have or shall authorize intermediaries who hold and/or manage and/or facilitate transactions with respect to the Held-Away Assets to share with us relevant records of your holdings and transactions, and where we have agreed to maintain a record of your Held-Away Assets under Clause 7.1 above, you instruct us to, without any further verification or action on our part, consider the record of your Held-Away Assets disclosed to us by the intermediaries.</span></li>
        <li class="li1"><span class="s2">Where you are in receipt of your Account statements from any of our other divisions or Associates for transactions undertaken through them and/or Assets managed by them, we may not include these Assets in our Account statement to you even if we have provided our Services in relation to these Assets. You authorize us to disclose information we have in relation to such Assets to our other divisions or Associates to enable them to include such information in the Account statements issued by them to you. We may assume, unless you notify us of any error or omission in your Account statement issued by us within 30 days of receipt of the Account statement, that the contents of such statement are correct.</span></li>
        <li class="li1"><span class="s2">Where you have requested us to include your Held-Away Assets in your statement of Accounts, you authorise us to share your records maintained with us with our Associates or other divisions of Hedgeloop, as may be permitted by applicable regulations.</span></li>
        <li class="li1"><span class="s2">We may, at your request but we are not obliged to, benchmark (where applicable and practicable) the performance of the Assets including Held-Away Assets against a benchmark index as mutually agreed. You understand and accept that the benchmark index may not be truly representative of the Advisory Services rendered by us for various reasons including without limitation: (a) the number of Securities may be different in comparison to the benchmark index and (b) the weightages of individual Securities may vary from weightages in the benchmark index. We may from time to time, review the benchmark selection process and make suitable changes as to use of the benchmark, or related to composition of the benchmark.</span></li>
    </ol>
</ol>
<p><span class="s2"><strong>Charges and Fees</strong></span></p>
<ol class="ol1">
    <li class="li1"><span class="s2">You agree to pay fees to us at the rates and in the manner set out in the tariff schedule or as may be mutually agreed by and between us from time to time (the <strong>&ldquo;Advisory Service</strong> <strong>Fees&rdquo;</strong>). The Advisory Service Fees may be independent of the returns accrued to or losses incurred by you on the performance of Assets and Investments purchased, sold or held by you pursuant to the Advisory Services rendered by us under these Advisory Services Terms and Conditions.</span></li>
    <li class="li1">
        <p class="p1"><span class="s1">The Advisory Service Fees are exclusive of any service tax or like tax paid or payable by us in relation to the Advisory Services rendered by us and all such taxes shall be borne by you and you agree to reimburse us.</span></p>
    </li>
    <li class="li1">
        <p class="p1">In addition to the Advisory Service Fees, all costs, fees, charges and expenses of whatsoever nature incurred by us or any other person appointed by us arising out of or in connection with or in relation to the rendering of the Advisory Services or the performance of any act pursuant to or in connection with these Advisory Services Terms and Conditions incurred on your behalf, including without limitation, all incidental and ancillary documentation pursuant to these Advisory Services Terms and Conditions, shall be paid or reimbursed by you.</p>
    </li>
</ol>
<p class="p1"><strong><span class="s1">Section C &ndash; Definitions</span></strong></p>
<p class="p1"><strong>Definitions</strong></p>
<p class="p2"><strong>Assets </strong>means the portfolio of Investments acquired by you including Held-Away Assets as defined in Clause 7.2 above;</p>
<p class="p5"><span class="s2"><strong>Client Profiler </strong>means the client profiler form completed by you in which you provide certain personal and financial information;</span></p>
<p class="p5"><span class="s2"><strong>Adviser </strong>means an investment adviser as defined in the Securities Exchange Board of India (Investment Advisers) Regulations 2013, as may be amended, supplemented or replaced from time to time;</span></p>
<p class="p8"><span class="s2"><strong>Advisory Services </strong>means the provision of advisory services by us to you in accordance with Clause 2 of these Terms and Conditions;</span></p>
<p class="p9"><span class="s2"><strong>Advisory Services Application Form </strong>means the application form completed and signed by you requesting the provision of the Advisory Services by us to you, which incorporates these Terms and Conditions;</span></p>
<p class="p5"><span class="s2"><strong>Regulations </strong>mean the Securities Exchange Board of India (Investment Advisers) Regulations 2013 and all other regulations applicable to an Investment Adviser as defined therein, as may be amended, supplemented or replaced from time to time; and</span></p>
<p class="p5"><span class="s2"><strong>Securities </strong>mean securities as defined under the Securities Contracts (Regulation) Act, 1956 as amended, supplemented or replaced from time to time and any other Investments purchased, sold, held, acquired or otherwise dealt with by you.</span></p>
</p>
                </div>
                <div className='agree-button'>
                <Button variant="contained" size="medium" onClick={onSubmit}>Agree Terms & Conditions</Button>
                </div>
            </div>
            </div>
}
        </>
    )
}

export default TermsVerify