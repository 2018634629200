import React, { Fragment, useState } from 'react';
import EmailVerify from './email_verify';
import TermsVerify from './terms';
import UserVerify from './user_verify';
import RiskProfile from './risk_profile';
import CuratedPortfolios from './curated_ports';


const Register = () => {
    const [step, setStep] = useState(1);
    const [riskProfile, setRiskProfile] = useState('very_conservative');

    const nextStep = () => {
        setStep(prevStep => prevStep + 1 )
    }

    const customStep = (stepValue) => {
        setStep(stepValue)
    }

    switch(step){
        case 1:
            return(
                <Fragment>
                    <EmailVerify nextStep={nextStep} customStep={customStep}/>
                </Fragment>
            )
        case 2:
            return (
                <Fragment>
                    <TermsVerify nextStep={nextStep}></TermsVerify>
                </Fragment>
            )
        case 3:
            return (
                <Fragment>
                    <UserVerify nextStep={nextStep}></UserVerify>
                </Fragment>
            )
        case 4:
            return (
                <Fragment>
                    <RiskProfile nextStep={nextStep} setRiskProfile={setRiskProfile} riskProfile={riskProfile}></RiskProfile>
                </Fragment>
            )
        case 5:
            return (
                <Fragment>
                    <CuratedPortfolios nextStep={nextStep} riskProfile={riskProfile}></CuratedPortfolios>
                </Fragment>
            )
        default: return <div>{step}</div>
    }
}

export default Register